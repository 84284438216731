import { v4 as uuidv4 } from "uuid";

import { convertTimestampToMilliseconds } from "common";

import { EventLog, EventLogResponse } from "../../types";

export const mapEventLogResponse = (eventLogResponse: EventLogResponse[]): EventLog[] =>
  eventLogResponse.map((response) => ({
    ...response,
    id: uuidv4(),
    recorded: response.time === "" ? null : convertTimestampToMilliseconds(response.time),
    received: response.received === "" ? null : convertTimestampToMilliseconds(response.received),
    data: JSON.stringify(response.data)
  }));
