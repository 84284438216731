export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" fill="none">
    <g clipPath="url(#a)">
      <circle cx="11.293" cy="12.293" r="10" fill="#fff" />
      <path
        fill="#008B40"
        fillRule="evenodd"
        d="M11.293 23.586c6.237 0 11.293-5.056 11.293-11.293C22.586 6.056 17.53 1 11.293 1 5.056 1 0 6.056 0 12.293c0 6.237 5.056 11.293 11.293 11.293Zm0-1.293c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
        clipRule="evenodd"
      />
      <circle cx="18.707" cy="12.293" r="11.293" fill="#008B40" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h30v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
