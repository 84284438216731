import { useTheme } from "@mui/material/styles";

export const ManageColumnsIcon = () => {
  const theme = useTheme();

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 21V3H16V21H18ZM13 21V3H11V21H13ZM6 21H8V3H6L6 21Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
