import { Hub } from "aws-amplify";
import { useEffect, useState } from "react";

import { getAccessToken } from "./getAccessToken";

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    console.log("useAccessToken effect");

    const unsubscribe = Hub.listen("auth", async ({ payload }) => {
      console.log("auth event", payload);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    (async () => {
      const token = await getAccessToken();
      setAccessToken(token);
    })();
  }, []);

  return accessToken;
};
