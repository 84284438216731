import { Identifier } from "react-admin";

import { httpClient } from "../../../../services";

const API_URL = process.env.REACT_APP_API_URL;
const IDENTIFY_API_URL = `${API_URL}/lamp`;

const IDENTIFY_COMMAND_BODY = {
  duration: 60,
  activate: true
};

export const useIdentifyTracker = () => {
  return (trackerId: Identifier) =>
    httpClient(`${IDENTIFY_API_URL}/${trackerId}/buzzer`, {
      method: "POST",
      body: JSON.stringify(IDENTIFY_COMMAND_BODY)
    });
};
