import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const HeaderContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;

  align-items: center;
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(4)};
`
);

export const LogoAndWelcomeTextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderText = styled(Typography)(
  ({ theme }) => `
    margin-top: ${theme.spacing(4)};
  `
);

export const AgentNameContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${theme.spacing(4)};
`
);
