import { convertTimestampToMilliseconds } from "common";

import { LocateData, LocateDataResponse } from "../../types";

export const mapLocateDataResponse = (response: LocateDataResponse): LocateData => ({
  ...response,
  lastSeen: convertTimestampToMilliseconds(response.lastSeen),
  id: response.tid,
  status: response.locate?.status ?? null,
  lowBattery: response.status?.lowBatt ?? false
});
