import { GridEventListener } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { MutableRefObject, useCallback, useMemo } from "react";

import { usePermissionsContext } from "../../auth";

export const usePersistedGridState = (
  gridApiRef: MutableRefObject<GridApiPro>,
  storageKey?: string
) => {
  const { user } = usePermissionsContext();

  const userStorageKey = `${user?.id}_${storageKey}`;

  const gridState = useMemo(() => {
    if (!storageKey || !user?.id) return;

    const state = localStorage.getItem(userStorageKey);
    if (state) {
      try {
        return JSON.parse(state);
      } catch {
        localStorage.removeItem(userStorageKey);
        return null;
      }
    }

    return null;
  }, [storageKey, user?.id, userStorageKey]);

  const onGridStateChange: GridEventListener<"stateChange"> = useCallback(() => {
    if (!storageKey || !user?.id) return;

    const exportedState = gridApiRef.current?.exportState();
    if (!exportedState) return;

    localStorage.setItem(userStorageKey, JSON.stringify(gridApiRef.current.exportState()));
  }, [gridApiRef, storageKey, user?.id, userStorageKey]);

  return {
    gridState,
    onGridStateChange
  };
};
