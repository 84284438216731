import { GridFilterInputValueProps } from "@mui/x-data-grid/components/panel/filterPanel/GridFilterInputValueProps";
import { GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";

import LabeledBooleanFilterInput from "./LabeledBooleanFilterInput";

const COMPARE_OPERATOR = "is";
export const getLabeledBooleanFilterOperators = (
  displayOptions: [string, string],
  defaultCompare: unknown
): GridFilterOperator[] => {
  const lowerCaseOptions = displayOptions.map((option) => option.toLowerCase());

  return [
    {
      label: COMPARE_OPERATOR,
      value: COMPARE_OPERATOR,
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }

        return (params): boolean => {
          const filterValue = filterItem.value;
          const paramValue = params ?? defaultCompare;

          switch (filterValue) {
            case lowerCaseOptions[0]:
              return paramValue === defaultCompare;
            case lowerCaseOptions[1]:
              return paramValue !== defaultCompare;
            default:
              return true;
          }
        };
      },

      InputComponent: (props: GridFilterInputValueProps) => (
        <LabeledBooleanFilterInput {...props} options={displayOptions} />
      )
    }
  ];
};
