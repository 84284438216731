import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { ShowMoreContent } from "./components/ShowMoreContent/ShowMoreContent";
import { calculateItemsToShow } from "./helpers/calculateItemsToShow";

interface Props extends PropsWithChildren {
  containerWidth: number;
  showMoreTooltipContent: string;
}

export const ShowMoreOverflow = ({ children, containerWidth, showMoreTooltipContent }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [numberOfItemsToShow, setNumberOfItemsToShow] = useState<number | null>(null);

  useEffect(() => {
    const node = containerRef.current;
    if (!node || !node.children) {
      return;
    }

    const childNodes = Array.from(node.children);
    const itemsToShow = calculateItemsToShow({ containerWidth, childNodes });

    setNumberOfItemsToShow(itemsToShow || 1);
  }, [containerWidth, children]);

  if (!children || !Array.isArray(children)) {
    return null;
  }

  return (
    <>
      {numberOfItemsToShow !== null ? (
        <ShowMoreContent
          numberOfElementsToShow={numberOfItemsToShow}
          showMoreTooltipContent={showMoreTooltipContent}
        >
          {children}
        </ShowMoreContent>
      ) : null}

      {/* Hidden container with all the childrens - to calculate their widths */}
      <div ref={containerRef} style={{ visibility: "hidden" }}>
        {children}
      </div>
    </>
  );
};
