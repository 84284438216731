import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import {
  DataGridPro,
  DataGridProProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridFilterModel,
  GridFilterPanel,
  GridPaginationModel,
  GridValidRowModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import { MutableRefObject, ReactNode } from "react";

import { ACTIONS_COLUMN_ID, ROWS_PER_PAGE_OPTIONS } from "consts";

import { ToolbarWithPagination } from "./components/ToolbarWithPagination";
import { usePersistedGridState } from "./usePersistedGridState";

export interface DataGridProps<Model extends GridValidRowModel>
  extends Omit<DataGridProProps, "rows" | "columns"> {
  columnDefinitions: GridColDef<Model>[];
  data: Model[];
  rowCount?: number;
  loading?: boolean;

  hideFooter?: boolean;
  disableCheckboxSelection?: boolean;
  handleContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;

  storageKey?: string;
  Toolbar?: () => JSX.Element;
  paginationActions?: ReactNode;

  apiRef?: MutableRefObject<GridApiPro>;

  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails) => void;
  onPaginationModelChange?: (model: GridPaginationModel, details: GridCallbackDetails) => void;

  initialState?: GridInitialStatePro;
  modelName?: string;
}

export const DataGrid = <Model extends GridValidRowModel>({
  columnDefinitions,
  data,
  disableCheckboxSelection,
  handleContextMenu,
  Toolbar,
  hideFooter,
  apiRef,
  loading,
  storageKey,
  onSortModelChange,
  initialState,
  paginationActions,
  onFilterModelChange,
  onPaginationModelChange,
  modelName,
  ...rest
}: DataGridProps<Model>) => {
  const internalGridApiRef = useGridApiRef();
  const gridApiRef = apiRef ?? internalGridApiRef;

  const { gridState, onGridStateChange } = usePersistedGridState(gridApiRef, storageKey);

  return (
    <DataGridPro<Model>
      rows={data ?? []}
      columns={columnDefinitions}
      hideFooter={hideFooter}
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      loading={loading}
      checkboxSelection={!disableCheckboxSelection}
      disableRowSelectionOnClick={disableCheckboxSelection}
      initialState={{
        ...initialState,
        pinnedColumns: {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          right: [ACTIONS_COLUMN_ID]
        },
        ...gridState
      }}
      slotProps={{
        row: {
          onContextMenu: handleContextMenu
        },
        filterPanel: {
          filterFormProps: {
            valueInputProps: {
              InputComponentProps: {
                variant: "standard" // by default, the autocomplete component (is any of) has 'filled' variant
              }
            }
          }
        },
        toolbar: {
          hidePagination: hideFooter,
          Toolbar: Toolbar,
          paginationActions,
          disableCheckboxSelection,
          modelName
        },
        pagination: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "data-testid": "grid-bottom-pagination"
        }
      }}
      localeText={{ toolbarColumns: "" }}
      onStateChange={onGridStateChange}
      apiRef={gridApiRef}
      density="compact"
      sortingMode={onSortModelChange ? "server" : "client"}
      filterMode={onFilterModelChange ? "server" : "client"}
      paginationMode={onPaginationModelChange ? "server" : "client"}
      onSortModelChange={onSortModelChange}
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={onPaginationModelChange}
      pagination
      {...rest}
      slots={{
        toolbar: ToolbarWithPagination,
        filterPanel: GridFilterPanel,
        ...rest.slots
      }}
    />
  );
};
