import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { gridFilterModelSelector, useGridApiContext } from "@mui/x-data-grid-pro";

import { useCallback, useMemo } from "react";
import { useListContext } from "react-admin";

import { formatDate } from "common";

import { getFilterItemLabel } from "./helpers/getFilterItemLabel";

import * as S from "./ListDataGridFilterValues.styled";

const STATE_FILTER_ID = "state";
const LOW_BATTERY_FILTER_ID = "lowBatteryFilterId";
const LAST_SEEN_TO_FILTER_ID = "lastSeenToFilterId";
const LAST_SEEN_FROM_FILTER_ID = "lastSeenFromFilterId";
export const IS_EMPTY_OPERATOR = "isEmpty";

export const ListDataGridFilterValues = () => {
  const { filterValues, setFilters } = useListContext();

  const apiRef = useGridApiContext();
  const { items } = gridFilterModelSelector(apiRef);

  const filterItems = useMemo(
    () =>
      items.filter((filterItem) => filterItem.value || filterItem.operator === IS_EMPTY_OPERATOR),
    [items]
  );

  const onClearAll = useCallback(() => {
    apiRef.current.setFilterModel({ items: [] });
    setFilters([], []);
  }, [apiRef, setFilters]);

  const handleDelete = useCallback(
    (filterId: number | string | undefined) => () => {
      switch (filterId) {
        case undefined:
          return;
        case STATE_FILTER_ID:
          setFilters({ ...filterValues, state: undefined }, []);
          break;
        case LOW_BATTERY_FILTER_ID:
          setFilters({ ...filterValues, status: undefined }, []);
          break;
        case LAST_SEEN_TO_FILTER_ID:
          setFilters({ ...filterValues, lastSeenTo: undefined }, []);
          break;
        case LAST_SEEN_FROM_FILTER_ID:
          setFilters({ ...filterValues, lastSeenFrom: undefined }, []);
          break;
        default:
          apiRef.current.setFilterModel({ items: items.filter(({ id }) => id !== filterId) });
      }
    },
    [apiRef, filterValues, items, setFilters]
  );

  if (
    filterItems.length === 0 &&
    !filterValues?.state &&
    !filterValues?.status?.lowBatt &&
    !filterValues?.lastSeenTo
  ) {
    return null;
  }

  return (
    <S.FiltersContainer>
      {filterValues?.state && (
        <Chip
          key="stateFilter"
          label={`State is ${filterValues.state}`}
          onDelete={handleDelete(STATE_FILTER_ID)}
        />
      )}

      {filterValues?.status?.lowBatt && (
        <Chip
          key="lowBatteryFilter"
          label={`Low battery is ${filterValues.status.lowBatt}`}
          onDelete={handleDelete(LOW_BATTERY_FILTER_ID)}
        />
      )}

      {filterValues?.lastSeenTo && (
        <Chip
          key="lastSeenToFilter"
          label={`Last seen to is ${formatDate(new Date(filterValues.lastSeenTo))}`}
          onDelete={handleDelete(LAST_SEEN_TO_FILTER_ID)}
        />
      )}

      {filterValues?.lastSeenFrom && (
        <Chip
          key="lastSeenFromFilter"
          label={`Last seen from is ${formatDate(new Date(filterValues.lastSeenFrom))}`}
          onDelete={handleDelete(LAST_SEEN_FROM_FILTER_ID)}
        />
      )}

      {filterItems.map((filterItem) => (
        <Chip
          key={filterItem.id + filterItem.value}
          label={getFilterItemLabel(filterItem)}
          onDelete={handleDelete(filterItem.id)}
        />
      ))}

      <Button sx={{ mr: 1 }} variant="text" onClick={onClearAll}>
        Clear all
      </Button>
    </S.FiltersContainer>
  );
};
