import Typography from "@mui/material/Typography";
import {
  GridPagination,
  gridExpandedRowCountSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import { ReactNode, useMemo } from "react";

import * as S from "./ToolbarWithPagination.styled";

import type { GridToolbarProps } from "@mui/x-data-grid/components/toolbar/GridToolbar";

interface ToolbarWithPaginationProps extends GridToolbarProps {
  Toolbar?: () => JSX.Element;
  hidePagination?: boolean;
  paginationActions?: () => ReactNode;
  disableCheckboxSelection?: boolean;
  modelName?: string;
}

export const ToolbarWithPagination = ({
  hidePagination,
  Toolbar,
  paginationActions,
  disableCheckboxSelection,
  modelName
}: ToolbarWithPaginationProps) => {
  const apiRef = useGridApiContext();
  const totalRowsCount = useGridSelector(apiRef, gridExpandedRowCountSelector);

  const selectedRows = apiRef.current.getSelectedRows();
  const selectedRowsCount = selectedRows.size;

  const summaryText = useMemo(() => {
    const isSingleRow = totalRowsCount === 1;

    const text = `${totalRowsCount} ${modelName ?? "row"}${isSingleRow ? "" : "s"}`;

    if (!disableCheckboxSelection) {
      return `${text} • ${selectedRowsCount} selected`;
    }

    return text;
  }, [disableCheckboxSelection, modelName, selectedRowsCount, totalRowsCount]);

  return (
    <>
      {Toolbar && <Toolbar />}

      {!hidePagination && (
        <S.PaginationContainer>
          <S.NumberAndActionsContainer>
            <>
              <Typography variant="body2">{summaryText}</Typography>
              {paginationActions}
            </>
          </S.NumberAndActionsContainer>
          <GridPagination data-testid="grid-top-pagination" />
        </S.PaginationContainer>
      )}
    </>
  );
};
