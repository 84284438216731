import { styled } from "@mui/material/styles";

export const CellContainer = styled("div")`
  position: relative;
  width: 100%;

  cursor: pointer;
`;

export const CellIconContainer = styled("div")`
  position: absolute;
  top: 6px;
  right: -10px;
  width: 24px;

  border-radius: 50%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
