import { useMemo } from "react";
import { useListContext } from "react-admin";

/**
 * The purpose of this hook is to retrieve the query key associated with the list data stored by React Admin.
 * This is useful when you want to update the data (e.g. based on websocket events)
 */
export const useListQueryKey = (overrides?: { page: number }) => {
  const { page, perPage, resource, filterValues, filter, sort } = useListContext();

  return useMemo(
    () => [
      resource,
      "getList",
      {
        pagination: { page: overrides?.page ?? page, perPage },
        filter: { ...filterValues, ...filter },
        sort
      }
    ],
    [filter, filterValues, overrides?.page, page, perPage, resource, sort]
  );
};
