const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const APP_NAME = "LUNA XIO";

export const ROWS_PER_PAGE = 20;

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];

export const DEFAULT_STALE_TIME = 60000;

export const TABLE_REFETCH_INTERVAL = 60000;
export const ACTIONS_COLUMN_ID = "actions";
export const CHECK_COLUMN_ID = "__check__";
export const DEFAULT_MOCKED_TRACKER_ID = ENVIRONMENT ? `${ENVIRONMENT}-1` : "";

export const SB_STORAGE_KEY = "supabase.auth.token";

export const IS_EMPTY_OPERATOR = "isEmpty";

export const AGENT_TRACKERS_FILTER_KEY = "trackerId";
export const AGENT_SENSOR_AGG_KEY = "agg";
export const TOOLBAR_BUTTON_SIZE = {
  width: "40px",
  height: "40px"
};
