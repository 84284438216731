import CircularProgress from "@mui/material/CircularProgress";
import { PropsWithChildren, useMemo } from "react";

import { PermissionsContext } from "./context";
import { getCurrentUserPermissions } from "./permissions";
import * as S from "./styled";
import { AuthErrorPage } from "../../components";
import { useCurrentUser } from "../../services";

export const PermissionsContextProvider = ({ children }: PropsWithChildren) => {
  const { isLoading, isError, data: currentUser } = useCurrentUser();

  const contextData = useMemo(() => {
    if (!currentUser) return null;

    const userPermissions = getCurrentUserPermissions(currentUser);

    return {
      user: currentUser,
      canAdmin: userPermissions.canAdmin,
      canEdit: userPermissions.canEdit,
      canSuperAdmin: userPermissions.canSuperAdmin
    };
  }, [currentUser]);

  if (isError) {
    return <AuthErrorPage />;
  }

  if (isLoading) {
    return (
      <S.SpinnerContainer>
        <CircularProgress />
      </S.SpinnerContainer>
    );
  }

  return contextData ? (
    <PermissionsContext.Provider value={contextData}>{children}</PermissionsContext.Provider>
  ) : null;
};
