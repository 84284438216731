import Avatar from "@mui/material/Avatar";
import MuiFormControl from "@mui/material/FormControl";
import MuiFormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import Typography from "@mui/material/Typography";

import { colors } from "theme";

export const ToggleFormControl = styled(MuiFormControl)`
  flex-direction: column;
  width: 100%;
`;

export const ToggleFormLabel = styled(MuiFormLabel)(({ theme }) => ({
  margin: theme.spacing(0, 2.5, 2, 0)
}));

export const ToggleCardGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  gap: theme.spacing(0.75),
  width: "100%",

  display: "flex",
  flexDirection: "column",
  "& .MuiToggleButtonGroup-grouped": {
    border: `1px solid`,

    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[300],

      "&.Mui-selected": {
        border: `1px solid ${theme.palette.primary.main}`
      }
    },

    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[300],

      "&.Mui-selected": {
        border: `1px solid ${theme.palette.primary.main}`
      }
    }
  }
}));

export const ToggleCardAvatar = styled(Avatar)(() => ({
  backgroundColor: colors.blueLight
}));

export const ToggleCard = styled(MuiToggleButton)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.text.primary,

  width: "100%",

  "&.Mui-selected": {
    color: theme.palette.text.primary,
    borderColor: theme.palette.primary.main
  }
}));

export const ToggleHelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary
}));
