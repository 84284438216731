import { useCallback } from "react";
import { useResourceContext } from "react-admin";
import { useNavigate } from "react-router-dom";

export const useGoToPreviousPage = () => {
  const resource = useResourceContext();
  const navigate = useNavigate();

  return useCallback(() => {
    window.history.state && window.history.state.idx > 0 ? navigate(-1) : navigate(`/${resource}`);
  }, [navigate, resource]);
};
