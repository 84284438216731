import { withAuthenticator } from "@aws-amplify/ui-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Amplify, I18n } from "aws-amplify";

import { QueryClientProvider } from "react-query";

import { LoginScreenHeader } from "components";

import { PermissionsContextProvider } from "./auth";

import { LunaApp } from "./modules/LunaApp";
import { queryClient } from "./services";

import "@aws-amplify/ui-react/styles.css";

I18n.setLanguage("en");
I18n.putVocabulariesForLanguage("en", {
  "Forgot your password?": "Forgot password?",
  Username: "Email"
});

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_CLIENT_ID
  }
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <PermissionsContextProvider>
          <LunaApp />
        </PermissionsContextProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    SignIn: {
      Header: () => (
        <LoginScreenHeader
          title="Welcome to Luna Console"
          agentName={process.env.REACT_APP_LUNA_AGENT_NAME}
        />
      )
    }
  }
});
