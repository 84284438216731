import Button from "@mui/material/Button";
import { SaveButton, Toolbar, useRefresh } from "react-admin";

import { useGoToPreviousPage, useLayoutMode } from "common";

export const FormToolbar = () => {
  const layoutMode = useLayoutMode();
  const refresh = useRefresh();
  const goToPreviousPage = useGoToPreviousPage();

  return (
    <Toolbar
      sx={{
        justifyContent: "flex-end",
        backgroundColor: layoutMode === "dark" ? "transparent" : "white",
        gap: "5px"
      }}
    >
      <Button variant="text" type="button" onClick={goToPreviousPage} data-testid="cancelButton">
        Cancel
      </Button>
      <SaveButton variant="contained" icon={<span />} onClick={refresh} data-testid="saveButton" />
    </Toolbar>
  );
};
