import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

export const AddButton = styled(IconButton)(
  ({ theme }) => `
  color: ${theme.palette.error.contrastText};
  background-color: ${theme.palette.primary.main};

  :hover {
    background-color: ${theme.palette.primary.dark};
  }
`
);
