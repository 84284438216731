import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateResult
} from "react-admin";

import { mapLocateDataResponse } from "./helpers/mapLocateDataResponse";
import { LocateData } from "./types";
import { httpClient } from "../../services";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "presence";

export const locateDataProvider: DataProvider = {
  getList: async (_, _params: GetListParams): Promise<GetListResult> => {
    const locateData: LocateData[] = await httpClient(`${API_URL}/${RESOURCE_NAME}`).then(
      ({ json }) => json.map(mapLocateDataResponse)
    );

    return {
      data: locateData,
      total: locateData.length
    };
  },

  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => {
    const proximityData: LocateData[] = await httpClient(`${API_URL}/${RESOURCE_NAME}`).then(
      ({ json }) => json.map(mapLocateDataResponse)
    );

    return {
      data: proximityData
    };
  },

  // TODO not implemented yet
  getOne: async (_, _params: GetOneParams): Promise<GetOneResult> => Promise.resolve({} as any),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  create: (_, _params) => Promise.resolve({ data: {} as any }),
  update: (_, _params): Promise<UpdateResult> => Promise.resolve({ data: {} as any }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  delete: (_, _params) => Promise.resolve({ data: {} as any }),
  deleteMany: async (_, _params) => Promise.resolve({})
};
