import type { Tracker, TrackerState } from "../../types";

export interface TrackersFilters {
  query?: string;
  tag?: string;
  state?: TrackerState | "All" | "Unsecured";
  status?: {
    lowBatt?: boolean;
  };
  isPresent?: boolean;
  lastSeenTo?: number;
  lastSeenFrom?: number;
}

export const getFilteredTrackersList = (
  trackers: Tracker[],
  { state, query, status, isPresent, lastSeenTo, lastSeenFrom }: TrackersFilters
) => {
  let filteredResults = [...trackers];

  if (query !== undefined) {
    const lowerCaseQuery = query.toLowerCase();

    filteredResults = filteredResults.filter(
      (tracker) =>
        tracker.name?.toLowerCase().includes(lowerCaseQuery) ||
        tracker.tid.toLowerCase().includes(lowerCaseQuery) ||
        tracker.tags?.some((trackerTag) => trackerTag.toLowerCase().includes(lowerCaseQuery))
    );
  }

  if (state !== undefined) {
    switch (state) {
      case "All":
        break;
      case "Unsecured":
        filteredResults = filteredResults.filter((tracker) => tracker.secured === undefined);
        break;
      default:
        filteredResults = filteredResults.filter((tracker) => tracker.state === state);
        break;
    }
  }

  if (status?.lowBatt !== undefined) {
    filteredResults = filteredResults.filter(
      (tracker) => tracker.status?.lowBatt === status.lowBatt
    );
  }

  if (isPresent !== undefined) {
    filteredResults = filteredResults.filter((tracker) => tracker.isPresent === isPresent);
  }

  if (lastSeenTo !== undefined) {
    filteredResults = filteredResults.filter(
      (tracker) => tracker.lastSeen && tracker.lastSeen < lastSeenTo
    );
  }

  if (lastSeenFrom !== undefined) {
    filteredResults = filteredResults.filter(
      (tracker) => tracker.lastSeen && tracker.lastSeen > lastSeenFrom
    );
  }

  return filteredResults;
};
