import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const ErrorContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  svg {
    width: 60px;
    height: 60px;
  }
`;
