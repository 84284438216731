import { useContext } from "react";

import { PermissionsContext } from "./context";

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);

  if (context === undefined || context === null) {
    throw new Error("usePermissionsContext must be used within a provider");
  }

  return context;
};
