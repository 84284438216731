import { useLayoutMode } from "../useLayoutMode";

const LIGH_MAP_STYLE = "mapbox://styles/mapbox/light-v9";
const DARK_MAP_STYLE = "mapbox://styles/mapbox/dark-v11";

export const useMapStyle = () => {
  const layoutMode = useLayoutMode();

  return layoutMode === "dark" ? DARK_MAP_STYLE : LIGH_MAP_STYLE;
};
