import Menu from "@mui/material/Menu";
import React from "react";

import { MenuPosition } from "./types";

interface Props {
  menuPosition: MenuPosition | null;
  handleClose: () => void;
}

export const DataGridContextMenu = ({
  menuPosition,
  handleClose,
  children
}: React.PropsWithChildren<Props>) => {
  return (
    <Menu
      open={menuPosition !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        menuPosition !== null ? { top: menuPosition.mouseY, left: menuPosition.mouseX } : undefined
      }
      componentsProps={{
        root: {
          onContextMenu: (event: React.MouseEvent) => {
            event.preventDefault();
            handleClose();
          }
        }
      }}>
      {children}
    </Menu>
  );
};
