import { User } from "../../types";

interface UserFilters {
  query?: string;
}

export const getFilteredUserList = (users: User[], { query }: UserFilters) => {
  let filteredUsers = [...users];

  if (query !== undefined) {
    const lowerCaseQuery = query.toLowerCase();

    filteredUsers = filteredUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(lowerCaseQuery) ||
        user.email.toLowerCase().includes(lowerCaseQuery) ||
        user.role.toLowerCase().includes(lowerCaseQuery)
    );
  }

  return filteredUsers;
};
