import { combineDataProviders } from "react-admin";

import { commandsDataProvider } from "./commands";
import { eventLogDataProvider } from "./eventLog";
import { locateDataProvider } from "./locate";
import { notificationsAggregatedDataProvider } from "./notificationsAggregated";
import { notificationsFeedDataProvider } from "./notificationsFeed";
import { proximityDataProvider } from "./proximity";
import { proximityFeedDataProvider } from "./proximityFeed";
import { sensorDataDataProvider } from "./sensorData";
import { trackersDataProvider } from "./trackers";
import { usersDataProvider } from "./users";

export const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "trackers":
      return trackersDataProvider;
    case "users":
      return usersDataProvider;
    case "notificationsAggregated":
      return notificationsAggregatedDataProvider;
    case "notificationsFeed":
      return notificationsFeedDataProvider;
    case "commands":
      return commandsDataProvider;
    case "sensorData":
      return sensorDataDataProvider;
    case "eventLog":
      return eventLogDataProvider;
    case "locate":
      return locateDataProvider;
    case "proximity":
      return proximityDataProvider;
    case "proximityFeed":
      return proximityFeedDataProvider;
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});
