import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const SpinnerContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;
