import { PropsWithChildren } from "react";

import { ListDataGridContext } from "./context";
import { ListDataGridContextValues } from "./types";

type Props = ListDataGridContextValues & PropsWithChildren;

export const ListDataGridContextProvider = ({
  children,
  hasCreatePage,
  onAddClick,
  filtersState
}: Props) => (
  <ListDataGridContext.Provider value={{ hasCreatePage, onAddClick, filtersState }}>
    {children}
  </ListDataGridContext.Provider>
);
