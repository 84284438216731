import { Coordinates } from "./base";
import { Notification } from "./notofications";

export type PresenceData = TrackerPresence[];

type TrackerPresence = Tracker & {
  presence: "absent" | "remote";
};

export const trackerStates = ["Securing...", "Deleting...", "Secured"] as const;
export type TrackerState = (typeof trackerStates)[number];

export const trackerTypes = ["tracker", "anchor", "asset", "mock"] as const;
export type TrackerType = (typeof trackerTypes)[number];

export const trackerAssignmentTypes = ["asset", "anchor"] as const;
export type TrackerAssignmentType = (typeof trackerAssignmentTypes)[number];

export interface Tracker {
  id: string;
  tid: string;
  lastSeen?: number;
  name?: string;
  tags?: string[];
  created: number;
  updated: number;
  secured?: number;
  deleted?: number | null;
  spaceId: string;
  assignment?: TrackerAssignmentType;
  type: TrackerType;
  device?: {
    k: number;
    firmware?: string;
    manufacturer?: string;
  };
  status?: {
    lowBatt?: boolean;
  };
  gateway?: {
    name: string;
    id: string;
  };
  location?: Coordinates;
  gwLocation?: Coordinates;
  gpsLocation?: Coordinates;
  meta?: {
    assetId?: string;
  };
  locate?: {
    status?: "lost" | "found";
    ownerId?: string;
    assetName?: string;
  };
  notifs?: Notification[];
  notes?: string;
  isPresent: boolean;
  recency: number;
  state: TrackerState;
  latency?: number;
}

export type TrackerResponse = Omit<Tracker, "id" | "latency" | "state">;
