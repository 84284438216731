import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Identifier, useDeleteMany, useNotify, useRefresh, useResourceContext } from "react-admin";

import { useHandleErrorResponse } from "common";

import { getDialogContent, getDialogTitle } from "./helpers";
import { useForceDeleteMany } from "./useForceDeleteMany";
import { ConfirmLoadingIcon } from "../../../../components";
import { DEFAULT_MOCKED_TRACKER_ID } from "../../../../consts";

interface Props {
  selectedIds: Identifier[];
  isOpen: boolean;
  forceDelete: boolean;

  handleClose: () => void;
  handleDeleteSuccess: () => void;
}

export const DeleteTrackersDialog = ({
  isOpen,
  selectedIds,
  forceDelete,
  handleClose,
  handleDeleteSuccess
}: Props) => {
  const resource = useResourceContext();
  const handleError = useHandleErrorResponse();
  const refresh = useRefresh();
  const notify = useNotify();

  const onDeleteSuccess = () => {
    refresh();
    notify("ra.notification.deleted", {
      type: "info",
      messageArgs: { smart_count: selectedIds.length }
    });
    handleDeleteSuccess();
  };

  const [deleteMany, { isLoading: isDeleteManyLoading }] = useDeleteMany(
    resource,
    { ids: selectedIds },
    {
      onSuccess: onDeleteSuccess,
      onError: (error: Error) => {
        handleError(error);
        handleClose();
      },
      mutationMode: "pessimistic"
    }
  );

  const { forceDeleteMany, isLoading: isForceDeleteManyLoading } = useForceDeleteMany(resource);

  const handleConfirm = async () => {
    if (forceDelete) {
      await forceDeleteMany(selectedIds, onDeleteSuccess);
    } else {
      await deleteMany();
    }
  };

  const isLoading = isDeleteManyLoading || isForceDeleteManyLoading;

  const isDefaultMockedTrackerOnTheList = selectedIds.includes(
    DEFAULT_MOCKED_TRACKER_ID as Identifier
  );

  const numberOfTrackers = selectedIds.length;

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {isDefaultMockedTrackerOnTheList ? (
        <>
          <DialogTitle>Selected mocked tracker cannot be removed.</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            {isLoading ? <ConfirmLoadingIcon /> : null}
            {getDialogTitle(numberOfTrackers, forceDelete)}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>{getDialogContent(forceDelete)}</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button disabled={isLoading} onClick={handleClose}>
              Cancel
            </Button>

            <Button disabled={isLoading} onClick={handleConfirm} data-testid="confirmDeleteButton">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
