import { get, isArray } from "lodash-es";
import React, { useCallback, useMemo } from "react";

import { MenuPosition } from "./types";
import { ACTIONS_COLUMN_ID, CHECK_COLUMN_ID } from "../../ListDataGrid";

interface MenuState {
  menuPosition: MenuPosition | null;
  rowId: string | null;
  clickedCell: {
    id: string | null;
    text: string | null;
  };
}

const initialState: MenuState = {
  menuPosition: null,
  rowId: null,
  clickedCell: {
    id: null,
    text: null
  }
};

export const useDataGridContextMenu = <DataType extends { id: string }>(
  gridData: DataType[] | undefined,
  notCopyableColumnsIds?: string[]
) => {
  const [contextMenuState, setContextMenuState] = React.useState<MenuState>(initialState);

  const contextMenuRow = useMemo(
    () =>
      contextMenuState ? gridData?.find((tracker) => tracker.id === contextMenuState.rowId) : null,
    [contextMenuState, gridData]
  );

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();

      const clickedCellId = (event.target as HTMLElement | null)
        ?.closest(".MuiDataGrid-cell")
        ?.getAttribute("data-field");

      const path = clickedCellId as keyof DataType;

      const fieldFromData = get(contextMenuRow, path);

      const textFromData = isArray(fieldFromData)
        ? fieldFromData.join(" ")
        : fieldFromData?.toString();

      const textFromRow = (event.target as HTMLDivElement).textContent;

      setContextMenuState({
        menuPosition: {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4
        },
        rowId: event.currentTarget.getAttribute("data-id"),
        clickedCell: {
          id: clickedCellId ?? null,
          text: textFromData ?? textFromRow
        }
      });
    },
    [contextMenuRow]
  );

  const handleClose = useCallback(() => {
    setContextMenuState((state) => ({ ...state, menuPosition: null }));
  }, []);

  const canCopyText = useMemo(() => {
    const clickedCellId = contextMenuState?.clickedCell.id;

    return !(
      !clickedCellId ||
      clickedCellId === ACTIONS_COLUMN_ID ||
      clickedCellId === CHECK_COLUMN_ID ||
      notCopyableColumnsIds?.includes(clickedCellId)
    );
  }, [contextMenuState?.clickedCell.id, notCopyableColumnsIds]);

  return {
    contextMenuState,

    handleClose,
    handleOpen,

    contextMenuRow,
    contextMenuRowId: contextMenuState?.rowId ?? null,
    menuPosition: contextMenuState?.menuPosition ?? null,

    canCopyText
  };
};
