import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PopoverPosition } from "@mui/material/Popover";
import { useState } from "react";
import { useCreatePath, useResourceContext } from "react-admin";
import { useNavigate } from "react-router-dom";

import {
  CsvResourceImportDialog,
  CsvResourceImportDialogProps
} from "./components/CsvResourceImportDialog";
import {
  KmlResourceImportDialog,
  KmlResourceImportDialogProps
} from "./components/KmlResourceImportDialog";

export interface AddResourceMenuProps {
  open: boolean;
  disableBulkImport?: boolean;
  fileLabel?: string;
  manualLabel?: string;
  handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  anchorPosition?: PopoverPosition;
}

type ImportDialogProps<TResource, TCreateEntity> =
  | {
      csvResourceImportDialogProps: Omit<
        CsvResourceImportDialogProps<TResource, TCreateEntity>,
        "isOpen" | "handleClose"
      >;
      kmlResourceImportDialogProps?: null;
    }
  | {
      csvResourceImportDialogProps?: null;
      kmlResourceImportDialogProps?: Omit<
        KmlResourceImportDialogProps<TCreateEntity>,
        "isOpen" | "handleClose"
      >;
    };

export const AddResourceMenu = <TResource, TCreateEntity>({
  open,
  disableBulkImport,
  fileLabel = "Import file",
  manualLabel = "Add manually",
  handleClose,
  anchorPosition,
  csvResourceImportDialogProps,
  kmlResourceImportDialogProps
}: AddResourceMenuProps & ImportDialogProps<TResource, TCreateEntity>) => {
  const createPath = useCreatePath();
  const resource = useResourceContext();
  const navigate = useNavigate();

  const [isFileImportOpen, setFileImportOpen] = useState(false);

  const onAddManuallyClick = () => {
    navigate(createPath({ resource, type: "create" }));
  };

  const onFileImportClick = () => {
    setFileImportOpen(true);
    handleClose();
  };

  const closeFileImport = () => {
    setFileImportOpen(false);
  };

  return (
    <>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem
          onClick={onFileImportClick}
          data-testid="importFileButton"
          disabled={disableBulkImport}
        >
          {fileLabel}
        </MenuItem>
        <MenuItem onClick={onAddManuallyClick} data-testid="addManuallyButton">
          {manualLabel}
        </MenuItem>
      </Menu>

      {csvResourceImportDialogProps && (
        <CsvResourceImportDialog
          isOpen={isFileImportOpen}
          handleClose={closeFileImport}
          {...csvResourceImportDialogProps}
        />
      )}
      {kmlResourceImportDialogProps && (
        <KmlResourceImportDialog
          isOpen={isFileImportOpen}
          handleClose={closeFileImport}
          {...kmlResourceImportDialogProps}
        />
      )}
    </>
  );
};
