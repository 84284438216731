import { DataProvider } from "react-admin";
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateResult
} from "react-admin";

import { mapEventLogResponse } from "./helpers/mapEventLogResponse";
import { EventLog } from "./types";
import { httpClient } from "../../services";

import { TRACKERS_FILTER_KEY } from "../const";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "logs";

const EVENT_LOG_API = `${API_URL}/${RESOURCE_NAME}`;

export const eventLogDataProvider: DataProvider = {
  getList: async (_, params: GetListParams): Promise<GetListResult> => {
    const trackerId = params.filter[TRACKERS_FILTER_KEY];

    if (!trackerId) {
      throw new Error("TrackerId is required!");
    }

    const searchParams = new URLSearchParams({
      skip: ((params.pagination.page - 1) * params.pagination.perPage).toString(),
      limit: params.pagination.perPage.toString()
    });

    const { json, headers } = await httpClient(
      `${EVENT_LOG_API}/${trackerId}?${searchParams.toString()}`
    );

    const eventLogData: EventLog[] = mapEventLogResponse(json);
    const totalLogs = Number(headers.get("x-total"));

    return {
      data: eventLogData,
      total: totalLogs
    };
  },

  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => {
    const commands: EventLog[] = await httpClient(EVENT_LOG_API).then(({ json }) => json);

    return {
      data: commands
    };
  },

  // TODO not implemented yet
  getOne: async (_, _params: GetOneParams): Promise<GetOneResult> => Promise.resolve({} as any),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  create: (_, _params) => Promise.resolve({ data: {} as any }),
  update: (_, _params): Promise<UpdateResult> => Promise.resolve({ data: {} as any }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  delete: (_, _params) => Promise.resolve({ data: {} as any }),
  deleteMany: async (_, _params) => Promise.resolve({})
};
