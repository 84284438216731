import { useCallback, useState } from "react";

import { Tracker } from "../../../../dataProvider";

export const useTrackersMapMarkerPopup = () => {
  const [popupTracker, setPopupTracker] = useState<Tracker | null>(null);

  const openPopup = useCallback((tracker: Tracker) => {
    setPopupTracker(tracker);
  }, []);

  const closePopup = useCallback(() => {
    setPopupTracker(null);
  }, []);

  return {
    popupTracker,
    openPopup,
    closePopup
  };
};
