import { Tracker } from "../../../../dataProvider";

export const mapTrackersToGeoJSON = (
  trackers: Tracker[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry> => {
  const trackersWithPosition = trackers.filter((tracker) => tracker.location);

  return {
    type: "FeatureCollection" as const,
    features: trackersWithPosition.map((tracker) => ({
      type: "Feature" as const,
      geometry: {
        type: "Point" as const,
        coordinates: [tracker.location?.lon ?? 0, tracker.location?.lat ?? 0]
      },
      properties: {
        id: tracker.id,
        title: tracker.name
      }
    }))
  };
};
