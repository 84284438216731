import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { PropsWithChildren } from "react";

import * as S from "./styled";

interface Props extends PropsWithChildren {
  numberOfElementsToShow: number;
  showMoreTooltipContent: string;
}

export const ShowMoreContent = ({
  numberOfElementsToShow,
  children,
  showMoreTooltipContent
}: Props) => {
  if (!children || !Array.isArray(children)) return null;

  return (
    <S.Container>
      {children.slice(0, numberOfElementsToShow)}
      {children.length - numberOfElementsToShow > 0 && (
        <Tooltip title={<Typography>{showMoreTooltipContent}</Typography>}>
          <S.ShowMoreText>+{children.length - numberOfElementsToShow} more</S.ShowMoreText>
        </Tooltip>
      )}
    </S.Container>
  );
};
