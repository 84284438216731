import * as CONSTS from "../consts";

interface Params {
  containerWidth: number;
  childNodes: Element[];
}

export const calculateItemsToShow = ({ childNodes, containerWidth }: Params) => {
  let itemsToShow = 0;
  let currentWidth = 0;

  const isSmallerThanContainerWidth = (width: number) => containerWidth >= width;

  for (let index = 0; index < childNodes.length; index++) {
    const currentNode = childNodes[index];
    const currentNodeWidth = currentNode.clientWidth + CONSTS.SHOW_MORE_ITEMS_GAP;

    if (isSmallerThanContainerWidth(currentWidth + currentNodeWidth)) {
      itemsToShow = itemsToShow + 1;
      currentWidth = currentWidth + currentNodeWidth;
    } else {
      // current number of items to show + more text width may exceed the size of the container
      // so we have to deduct some of the items from the list to get space for the text
      if (!isSmallerThanContainerWidth(currentWidth + CONSTS.SHOW_MORE_OVERFLOW_TEXT_WIDTH)) {
        for (let reverseIndex = index - 1; reverseIndex >= 0; reverseIndex--) {
          const childNode = childNodes[reverseIndex];
          currentWidth = currentWidth - childNode.clientWidth;
          itemsToShow = itemsToShow - 1;

          if (isSmallerThanContainerWidth(currentWidth + CONSTS.SHOW_MORE_OVERFLOW_TEXT_WIDTH)) {
            break;
          }
        }
      }

      break;
    }
  }

  return itemsToShow;
};
