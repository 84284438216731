import EngineeringIcon from "@mui/icons-material/Engineering";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const PageContainer = styled(Box)`
  height: 100vh;
  width: 100%;
  background-color: #fafafb;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;
`;

export const StyledButton = styled(Button)`
  background-color: #008b40;

  :hover {
    background-color: #008b40;
    opacity: 0.8;
  }
`;

export const ErrorIcon = styled(EngineeringIcon)`
  height: 64px;
  width: 64px;
  color: #008b40;
`;
