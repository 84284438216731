import { PostgrestFilterBuilder } from "@supabase/postgrest-js";

import { isArray } from "lodash-es";

import { ANCHOR_COLUMNS, AnchorType } from "./anchors";
import { ASSET_COLUMNS, AssetType } from "./assets";
import { SPACE_COLUMNS } from "./spaces";

export type Columns = typeof ASSET_COLUMNS | typeof ANCHOR_COLUMNS | typeof SPACE_COLUMNS;

export const ALL_COLUMNS = "*" as const;

export type AllColumns = typeof ALL_COLUMNS;

export type Query<TData> = PostgrestFilterBuilder<never, never, TData, unknown>;

export type DeviceType = AssetType | AnchorType;

export const operator = {
  equals: "equals",
  notEquals: "notEquals",
  is: "is",
  isNot: "isNot",
  contains: "contains",
  endsWith: "endsWith",
  startsWith: "startsWith",
  isAnyOf: "isAnyOf",
  isEmpty: "isEmpty",
  after: "after",
  greaterThan: "greaterThan",
  onOrAfter: "onOrAfter",
  greaterThanOrEqual: "greaterThanOrEqual",
  before: "before",
  lessThan: "lessThan",
  onOrBefore: "onOrBefore",
  lessThanOrEqual: "lessThanOrEqual",
  arrayContains: "array.contains",
  arrayIsEmpty: "array.isEmpty",
  isNull: "isNull",
  isNonNull: "isNonNull",
  isNotEmpty: "isNotEmpty",
  isTrue: "isTrue",
  isFalse: "isFalse",
  matches: "matches",
  notMatches: "notMatches"
} as const;

export const mathOperator = {
  equals: "=",
  notEquals: "!=",
  greaterThan: ">",
  greaterThanOrEqual: ">=",
  lessThan: "<",
  lessThanOrEqual: "<="
} as const;

export type Operator = (typeof operator)[keyof typeof operator];
export type MathOperator = (typeof mathOperator)[keyof typeof mathOperator];

export const noValueNeededOperations: Operator[] = [
  "array.isEmpty",
  "isEmpty",
  "isNull",
  "isNonNull",
  "isNotEmpty",
  "isTrue",
  "isFalse"
];

export const isValueValid = (value: unknown, operator: Operator): value is string | string[] =>
  ((typeof value === "string" || isArray(value)) && value.length > 0) ||
  noValueNeededOperations.includes(operator);

export const isOperatorValid = (op: unknown): op is Operator => {
  return (
    Object.values(operator).includes(op as Operator) ||
    Object.values(mathOperator).includes(op as MathOperator)
  );
};

export const isQuickFilterValid = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.length > 0 && value.every((v) => typeof v === "string");
};
