import DragHandleIcon from "@mui/icons-material/DragHandle";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

export const MapWrapper = styled(Card)(
  ({ theme }) => `
  margin: ${theme.spacing(2, 2, 0)};
  position: relative;

  & > .MuiCardContent-root {
    height: 100%;
  }

  & > .MuiCardContent-root:last-child {
    padding-bottom: ${theme.spacing(2)};
  }
`
);

export const ResizableListWrapper = styled(Card)(
  () => `
  height: 100%;
`
);

export const ResizableRoot = styled("div")(
  ({ theme }) => `
  padding: ${theme.spacing(0, 2)};

  width: 100%;
`
);

export const HandleIcon = styled(DragHandleIcon)(
  ({ theme }) => `
  position: absolute;

  bottom: -6px;
  left: 50%;

  transform: translateX(-50%);

  border-radius: 50%;
  cursor: row-resize;

  box-shadow: ${theme.shadows[3]};
  background-color: ${theme.palette.background.paper};
`
);
