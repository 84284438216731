export const MapPin = () => (
  <svg data-name="marker" xmlns="http://www.w3.org/2000/svg" width="20" height="41">
    <ellipse cx="10" cy="27" rx="9" ry="5" fill="#c4c4c4" opacity=".3" />
    <path
      data-name="shadow"
      fill="#bfbfbf"
      d="M10 32c5 0 9-2.2 9-5s-4-5-9-5-9 2.2-9 5 4 5 9 5Z"
      opacity=".3"
    />
    <path
      fill="#4264fb"
      stroke="#314ccd"
      d="M19.25 10.4a13.066 13.066 0 0 1-1.46 5.223 41.528 41.528 0 0 1-3.247 5.549 71.377 71.377 0 0 1-4.542 5.945l-.217-.258a73.206 73.206 0 0 1-4.327-5.725 42.268 42.268 0 0 1-3.246-5.553A12.978 12.978 0 0 1 .75 10.4 9.466 9.466 0 0 1 10 .75a9.466 9.466 0 0 1 9.25 9.65Z"
    />
    <path
      fill="#fff"
      stroke="#314ccd"
      d="M13.55 10A3.55 3.55 0 1 1 10 6.45 3.548 3.548 0 0 1 13.55 10Z"
    />
    <path fill="none" d="M0 0h20v48H0z" />
  </svg>
);
