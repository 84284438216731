import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const ListContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;

  height: calc(100vh - ${theme.spacing(8)});

  width: 100%;
  max-width: calc(100vw - ${theme.spacing(2)});

  padding: ${theme.spacing(1)};
  padding-top: ${theme.spacing(2)};
`
);
