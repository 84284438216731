import { convertTimestampToMilliseconds } from "common";

import { SensorData, SensorDataResponse } from "../../types";

export const mapSensorDataResponse = (sensorResponse: SensorDataResponse[]): SensorData[] =>
  sensorResponse
    .map((response) => {
      return response.data.map(([time, value]) => ({
        id: `${response.tid}_${time}`,
        tid: response.tid,
        type: response.type,
        time: convertTimestampToMilliseconds(time),
        value: value,
        unit: response.unit
      }));
    })
    .flat();
