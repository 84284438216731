import { convertTimestampToMilliseconds } from "common";

import { getTrackerState } from "./getTrackerState";

import { getTrackerLatency } from "../getTrackerLatency";

import type { Tracker, TrackerResponse } from "../../types";

export const mapTrackerResponse = (trackerResponse: unknown): Tracker => {
  const trackerData = trackerResponse as TrackerResponse;

  return {
    ...trackerData,
    id: trackerData.tid,
    isPresent: trackerData.presence === "remote",
    state: getTrackerState(trackerData),
    latency: getTrackerLatency(trackerData.notifs),
    created: convertTimestampToMilliseconds(trackerData.created),
    updated: convertTimestampToMilliseconds(trackerData.updated),
    secured: trackerData.secured && convertTimestampToMilliseconds(trackerData.secured),
    lastSeen: trackerData.lastSeen && convertTimestampToMilliseconds(trackerData.lastSeen)
  };
};
