import { GridFilterItem } from "@mui/x-data-grid-pro";

import { trackersColumnDefinitions } from "../../../../../../modules/Trackers/columnDefinitions";

import { IS_EMPTY_OPERATOR } from "../../ListDataGridFilterValues";

const getFilterHeaderName = (filterItem: GridFilterItem) =>
  trackersColumnDefinitions.find((definition) => definition.field === filterItem.field)
    ?.headerName ??
  filterItem.id ??
  "";

export const getFilterItemLabel = (filterItem: GridFilterItem) => {
  const filterName = getFilterHeaderName(filterItem);

  if (filterItem.operator === IS_EMPTY_OPERATOR) {
    return `${filterName} is empty`;
  }

  return `${filterName} ${filterItem.operator}: ${filterItem.value ?? ""} `;
};
