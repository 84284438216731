import { TrackerResponse, TrackerState } from "../../../types";

export const getTrackerState = (tracker: TrackerResponse): TrackerState => {
  if (tracker.deleted) {
    return "Deleting...";
  }

  if (tracker.secured) {
    return "Secured";
  }

  return "Securing...";
};
