import { useState } from "react";
import { Identifier, useDataProvider } from "react-admin";

export const useForceDeleteMany = (resource: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();

  const forceDeleteMany = async (
    selectedIds: Identifier[],
    onSuccess?: () => void
  ) => {
    setIsLoading(true);
    await dataProvider.forceDeleteMany(resource, { ids: selectedIds });
    setIsLoading(false);
    onSuccess?.();
  };

  return { forceDeleteMany, isLoading };
};
