import { User, UserResponse, UserRole } from "../../types";

const mapApiUserRoleToRole = (role?: string): UserRole =>
  role && ["reader", "writer", "admin"].includes(role) ? (role as UserRole) : "reader";

export const mapUserResponse = (userResponse: UserResponse): User => ({
  id: userResponse.email,
  name: userResponse.name,
  status: userResponse.status,
  role: mapApiUserRoleToRole(userResponse.role),
  email: userResponse.email
});
