import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateResult
} from "react-admin";

import { mapProximityDataResponse } from "./helpers/mapProximityDataResponse";
import { ProximityData } from "./types";
import { httpClient } from "../../services";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "proximity";

export const proximityDataProvider: DataProvider = {
  getList: async (_, params: GetListParams): Promise<GetListResult> => {
    const searchParams = new URLSearchParams({
      skip: ((params.pagination.page - 1) * params.pagination.perPage).toString(),
      limit: params.pagination.perPage.toString()
    });

    const { json, headers } = await httpClient(
      `${API_URL}/${RESOURCE_NAME}?${searchParams.toString()}`
    );

    const proximityData: ProximityData[] = json.map(mapProximityDataResponse);
    const totalProximityData = Number(headers.get("x-total"));

    return {
      data: proximityData,
      total: totalProximityData
    };
  },

  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => {
    const proximityData: ProximityData[] = await httpClient(`${API_URL}/${RESOURCE_NAME}`).then(
      ({ json }) => json.map(mapProximityDataResponse)
    );

    return {
      data: proximityData
    };
  },

  // TODO not implemented yet
  getOne: async (_, _params: GetOneParams): Promise<GetOneResult> => Promise.resolve({} as any),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  create: (_, _params) => Promise.resolve({ data: {} as any }),
  update: (_, _params): Promise<UpdateResult> => Promise.resolve({ data: {} as any }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  delete: (_, _params) => Promise.resolve({ data: {} as any }),
  deleteMany: async (_, _params) => Promise.resolve({})
};
