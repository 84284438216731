import { useEffect } from "react";

export const useAutoLogin = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const login = queryParams.get("un");
    const password = queryParams.get("pw");

    if (login && password) {
      const usernameInput = document.querySelector("input[name=username]");
      const passwordInput = document.querySelector("input[name=password]");

      usernameInput?.setAttribute("value", login || "");
      passwordInput?.setAttribute("value", password || "");

      window.history.pushState({}, document.title, window.location.pathname);

      const submitButton = document.querySelector("button[type=submit]") as
        | HTMLButtonElement
        | undefined;
      submitButton?.click();
    }
  }, []);
};
