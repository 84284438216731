import { useMemo } from "react";
import { useShowContext } from "react-admin";

/**
 * The purpose of this hook is to retrieve the query key associated with the show data stored by React Admin.
 * This is useful when you want to update the data (e.g. based on websocket events)
 */
export const useShowQueryKey = () => {
  const { resource, record } = useShowContext();

  const key = useMemo(() => [resource, "getOne", { id: record?.id }], [record?.id, resource]);

  return record ? key : null;
};
