import { ListDataGridAddButton } from "./components/ListDataGridAddButton";
import { ListDataGridExportButton } from "./components/ListDataGridExportButton";
import { ListDataGridManageColumnsButton } from "./components/ListDataGridManageColumnsButton";

export const ListDataGridGeneralActions = () => (
  <>
    <ListDataGridExportButton />
    <ListDataGridManageColumnsButton />
    <ListDataGridAddButton />
  </>
);
