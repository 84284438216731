import { convertTimestampToMilliseconds } from "common";

import { NotificationAggregate, NotificationAggregateResponse } from "../../types";

export const mapNotificationAggregateResponse = (
  notificationResponse: NotificationAggregateResponse
): NotificationAggregate => ({
  ...notificationResponse,
  id: notificationResponse.nid,
  time: notificationResponse.time * 1000,
  received: (notificationResponse.time + notificationResponse.age) * 1000,
  loc: notificationResponse.loc
    ? {
        lon: notificationResponse.loc[0],
        lat: notificationResponse.loc[1]
      }
    : null,
  timeGatewayOut:
    (notificationResponse.time + notificationResponse.age - notificationResponse.lag) * 1000,
  trackerId: notificationResponse.tid,
  startTime: convertTimestampToMilliseconds(notificationResponse.first),
  endTime: convertTimestampToMilliseconds(notificationResponse.last)
});
