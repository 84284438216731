import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";

export const ListCardContent = styled(CardContent)(`
  height: 100%;

  &:last-child {
    padding-bottom: 4px;
  }
`);
