import { styled } from "@mui/material/styles";

import { colors } from "theme";

export const DropzoneContainer = styled("div")(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed ${theme.palette.primary.main};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(3)};
    cursor: pointer;
    transition: ${theme.transitions.create(["border-color", "background-color"], {
      duration: 200
    })};
    &:hover {
      background-color: ${colors.blueLight};
      border-color: ${theme.palette.primary.dark};
    }
`
);
