import { z } from "zod";

import { WSNotificationResponse } from "../types";

const notificationSchema = z.object({
  tid: z.string(),
  loc: z
    .array(z.union([z.string(), z.number()]))
    .length(2)
    .optional(),
  gwid: z.string()
});

export function assertIsWSNotification(data: unknown): asserts data is WSNotificationResponse {
  notificationSchema.parse(data);
}
