import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateResult
} from "react-admin";

import { mapNotificationAggregateResponse } from "./helpers/mapNotificationAggregateResponse";
import { httpClient } from "../../services";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "notifs";

const NOTIFICATIONS_API = `${API_URL}/${RESOURCE_NAME}`;

export const DEFAULT_DATE_FROM_FILTER = Date.now() - 24 * 60 * 60 * 1000;

export const notificationsAggregatedDataProvider: DataProvider = {
  getList: async (_, params: GetListParams): Promise<GetListResult> => {
    const trackerId = params.filter.trackerId;

    const searchParams = new URLSearchParams({
      skip: ((params.pagination.page - 1) * params.pagination.perPage).toString(),
      limit: params.pagination.perPage.toString(),
      upto: params.filter.dateTo ?? "",
      tid: trackerId ?? ""
    });

    if (params.filter.gwid && params.filter.gwid !== "") {
      searchParams.append("gwid", params.filter.gwid);
    }

    const { json, headers } = await httpClient(`${NOTIFICATIONS_API}?${searchParams.toString()}`);

    const notifications = json.map(mapNotificationAggregateResponse);
    const totalNotifications = Number(headers.get("x-total"));

    return {
      data: notifications,
      total: totalNotifications
    };
  },

  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => {
    const notifications: Notification[] = await httpClient(NOTIFICATIONS_API).then(
      ({ json }) => json
    );

    return {
      data: notifications
    };
  },

  // TODO not implemented yet
  getOne: async (_, _params: GetOneParams): Promise<GetOneResult> => Promise.resolve({} as any),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  create: (_, _params) => Promise.resolve({ data: {} as any }),
  update: (_, _params): Promise<UpdateResult> => Promise.resolve({ data: {} as any }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  delete: (_, _params) => Promise.resolve({ data: {} as any }),
  deleteMany: async (_, _params) => Promise.resolve({})
};
