import { User } from "./types";
import { httpClient } from "../../services";

const CONFIRM_USER_API = process.env.REACT_APP_CONFIRM_USER_URL;

const generateSecret = () => {
  const array = new Uint8Array(20);
  crypto.getRandomValues(array);
  return Array.from(array, (val) => val.toString(36)).join("");
};

export const confirmUser = (user: User) => {
  const secret = generateSecret();
  const { id } = user;

  if (!CONFIRM_USER_API) {
    throw new Error("REACT_APP_CONFIRM_USER_URL API key is not set!");
  }

  httpClient(CONFIRM_USER_API, {
    method: "POST",
    body: JSON.stringify({ email: id, secret })
  });
};
