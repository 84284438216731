import { convertTimestampToMilliseconds } from "common";

import { Command, CommandResponse } from "../../types";

export const mapCommandResponse = (commandResponse: CommandResponse): Command => ({
  ...commandResponse,
  id: commandResponse.cid,
  created: convertTimestampToMilliseconds(commandResponse.created),
  updated: convertTimestampToMilliseconds(commandResponse.updated),
  sent: convertTimestampToMilliseconds(commandResponse.sent),
  responseReceived:
    commandResponse.replies && commandResponse.replies[0]?.updated
      ? convertTimestampToMilliseconds(commandResponse.replies[0].updated)
      : null
});
