import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";

import * as S from "./AuthErrorPage.styled";

export const AuthErrorPage = () => {
  const clearData = () => Auth.signOut();

  return (
    <S.PageContainer>
      <S.ErrorIcon />
      <Typography variant="h5">An error occured. Please contact the Luna XIO support.</Typography>
      <S.StyledButton onClick={clearData} variant="contained">
        Try again!
      </S.StyledButton>
    </S.PageContainer>
  );
};
