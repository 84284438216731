import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Identifier } from "react-admin";

import { useIdentifyTracker } from "../../../../dataProvider";

interface Props {
  selectedIds: Identifier[];
  isOpen: boolean;
  handleClose: () => void;
}

const getDialogTitle = (selectedIds: Identifier[]) => {
  const numberOfDevices = selectedIds.length;

  return numberOfDevices === 1
    ? `Alert on tracker ${selectedIds[0]}`
    : `Alert on ${numberOfDevices} trackers`;
};

export const DeviceAlertDialog = ({ selectedIds, isOpen, handleClose }: Props) => {
  const identifyTracker = useIdentifyTracker();

  const handleBlink = () => {
    selectedIds.forEach((id) => identifyTracker(id));
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{getDialogTitle(selectedIds)}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Selected tracker{selectedIds.length > 1 ? "s" : ""} will blink or buzz depending on
          hardware support.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleBlink}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
