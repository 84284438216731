import { styled } from "@mui/material/styles";

export const TopBarContainer = styled("div")(
  ({ theme }) => `
  flex-grow: 1;  
  margin-left: ${theme.spacing(2)};

  display: flex;
`
);

export const LogoContainer = styled("div")(
  ({ theme }) => `
  cursor: pointer;

  display: flex;
  align-self: center;

  margin-right: ${theme.spacing(2)};
`
);
