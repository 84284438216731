import CardContent from "@mui/material/CardContent";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useCallback, useMemo, useRef } from "react";
import Map, {
  FullscreenControl,
  GeoJSONSource,
  Layer,
  MapRef,
  NavigationControl,
  ScaleControl,
  Source
} from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { useMapStyle } from "common";

import { TrackersMapPopup } from "./components/TrackersMapPopup";
import { mapTrackersToGeoJSON } from "./helpers/mapTrackersToGeoJSON";
import { useTrackersMapMarkerPopup } from "./hooks/useTrackersMapMarkerPopup";
import { clusterCountLayer, clusterLayer, unclusteredPointLayer } from "./layers";

import { Tracker } from "../../dataProvider";

const initialViewState = {
  latitude: 45,
  longitude: -10,
  zoom: 2
};

export const TRACKERS_SOURCE_ID = "trackers";
const PIN_ZOOM_ON_CLICK = 12;

interface Props {
  data: Tracker[];
  selectedTrackersIds?: GridRowId[];
}

export const TrackersMap = ({ data }: Props) => {
  const mapStyle = useMapStyle();
  const mapRef = useRef<MapRef>(null);

  const { closePopup, openPopup, popupTracker } = useTrackersMapMarkerPopup();

  const trackersGeoData = useMemo(() => {
    if (!data) return;

    return mapTrackersToGeoJSON(data);
  }, [data]);

  const onClusterClick = useCallback((feature: mapboxgl.MapboxGeoJSONFeature) => {
    const clusterId = feature.properties?.cluster_id;
    const mapboxSource = mapRef.current?.getSource(TRACKERS_SOURCE_ID) as GeoJSONSource;

    const coordinates =
      feature?.geometry?.type === "Point"
        ? (feature.geometry.coordinates as [number, number])
        : null;

    if (!mapboxSource || !coordinates) return;

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      mapRef.current?.flyTo({
        center: coordinates,
        zoom,
        duration: 1000
      });
    });
  }, []);

  const onPinClick = useCallback(
    (trackerId: string) => {
      const tracker = data?.find((tracker) => tracker.id === trackerId);

      const currentZoom = mapRef.current?.getZoom();
      if (tracker && tracker.location) {
        mapRef.current?.flyTo({
          center: [tracker.location.lon, tracker.location.lat],
          zoom: currentZoom && currentZoom > PIN_ZOOM_ON_CLICK ? currentZoom : PIN_ZOOM_ON_CLICK,
          duration: 1500
        });

        openPopup(tracker);
      }
    },
    [data, openPopup]
  );

  const onClick = useCallback(
    (event: mapboxgl.MapLayerMouseEvent) => {
      const feature = event.features?.[0];
      const layerId = feature?.layer?.id;

      if (!feature || !layerId) return;

      if (layerId === clusterLayer.id) {
        onClusterClick(feature);
      } else if (layerId === unclusteredPointLayer.id) {
        const trackerId = feature?.properties?.id;

        if (trackerId) {
          onPinClick(trackerId);
        }
      }
    },
    [onPinClick, onClusterClick]
  );

  const loadPinImage = useCallback(() => {
    const map = mapRef.current;
    if (!map) return;

    if (!map.hasImage("map-pin")) {
      map.loadImage("/assets/marker-icon.png", (error, image) => {
        if (error) {
          throw error;
        }

        if (!map.hasImage("map-pin") && image) map.addImage("map-pin", image);
      });
    }
  }, []);

  return (
    <CardContent
      sx={{
        position: "relative",
        height: "100%"
      }}
    >
      <Map
        initialViewState={initialViewState}
        mapStyle={mapStyle}
        ref={mapRef}
        interactiveLayerIds={[clusterLayer.id ?? "", unclusteredPointLayer.id ?? ""]}
        onClick={onClick}
        onLoad={loadPinImage}
      >
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />

        {trackersGeoData && (
          <Source
            id={TRACKERS_SOURCE_ID}
            type="geojson"
            data={trackersGeoData}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
          </Source>
        )}

        {popupTracker && <TrackersMapPopup tracker={popupTracker} onClose={closePopup} />}
      </Map>
    </CardContent>
  );
};
