import * as S from "./ListDataGridToolbar.styled";
import { TrackersTableSelectedRowsActions } from "../../../../modules/Trackers/TrackerList/components/TrackersTableToolbar/components/TrackersTableSelectedRowsActions";
import { useListDataGridContext } from "../../context";

import { ListDataGridFilterIcon } from "../ListDataGridFilterIcon";

import { ListDataGridFilterValues } from "../ListDataGridFilterValues";
import { ListDataGridGeneralActions } from "../ListDataGridGeneralActions";
import { ListDataGridSearch } from "../ListDataGridSearch";

export const ListDataGridToolbar = () => {
  const { filtersState } = useListDataGridContext();
  const areFiltersEnabled = filtersState !== "disabled";

  return (
    <S.ToolbarContainer role="toolbar">
      <S.SearchAndFilterContainer>
        {areFiltersEnabled && (
          <>
            <ListDataGridSearch />
            <ListDataGridFilterIcon />
            <ListDataGridFilterValues />
          </>
        )}
      </S.SearchAndFilterContainer>

      <S.ActionsContainer>
        <TrackersTableSelectedRowsActions />
        <ListDataGridGeneralActions />
      </S.ActionsContainer>
    </S.ToolbarContainer>
  );
};
