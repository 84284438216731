import { GridColDef } from "@mui/x-data-grid-pro";

import { get, set } from "lodash-es";

import { ACTIONS_COLUMN_ID, CHECK_COLUMN_ID } from "../../../../../../constants";

export const mapColumnsToExport = (data: any[], columns: GridColDef<any, any, any>[]) => {
  try {
    return data.map((record) => {
      const mappedRecord = {};
      columns.forEach((column) => {
        if (
          column.field &&
          column.field !== CHECK_COLUMN_ID &&
          column.field !== ACTIONS_COLUMN_ID
        ) {
          set(mappedRecord, column.headerName ?? column.field, get(record, column.field));
        }
      });
      return mappedRecord;
    });
  } catch (error) {
    console.warn("An error occurred while mapping columns to export", error);
    return data;
  }
};
