import IconButton from "@mui/material/IconButton";
import {
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";

import { ManageColumnsIcon } from "./ManageColumnsIcon";

export const ListDataGridManageColumnsButton = () => {
  const apiRef = useGridApiContext();
  const { open, openedPanelValue } = useGridSelector(apiRef, gridPreferencePanelStateSelector);

  const showColumns = () => {
    if (open && openedPanelValue === GridPreferencePanelsValue.columns) {
      apiRef.current.hidePreferences();
    } else {
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
    }
  };

  return (
    <IconButton
      onClick={showColumns}
      title="Manage columns"
      aria-label="Manage columns"
      color="primary"
      sx={{ height: "40px", width: "40px" }}
      data-testid="manageColumnsButton"
    >
      <ManageColumnsIcon />
    </IconButton>
  );
};
