import type { Notification } from "../../../notificationsFeed";

export const getTrackerLatency = (notifs?: Notification[]) => {
  if (!notifs || notifs.length === 0) return;

  const sortedNotifsByTime = [...notifs].sort(
    (firstNotif, secondNotif) => secondNotif.time - firstNotif.time
  );

  return sortedNotifsByTime[0].age;
};
