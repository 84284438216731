export const LogoWithText = () => (
  <svg width="166" height="80" viewBox="0 0 166 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_11111_142358)">
      <g clipPath="url(#clip1_11111_142358)">
        <ellipse cx="27.611" cy="40.0007" rx="24.1667" ry="24.1667" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.611 67.2923C42.6838 67.2923 54.9027 55.0734 54.9027 40.0007C54.9027 24.9279 42.6838 12.709 27.611 12.709C12.5382 12.709 0.319336 24.9279 0.319336 40.0007C0.319336 55.0734 12.5382 67.2923 27.611 67.2923ZM27.611 64.1673C40.9579 64.1673 51.7777 53.3475 51.7777 40.0007C51.7777 26.6538 40.9579 15.834 27.611 15.834C14.2641 15.834 3.44434 26.6538 3.44434 40.0007C3.44434 53.3475 14.2641 64.1673 27.611 64.1673Z"
          fill="#008573"
        />
        <ellipse cx="45.5277" cy="40.0007" rx="27.2917" ry="27.2917" fill="#008573" />
      </g>
      <path d="M90.7047 31.0712H83.5406V17.7559H81.1526V33.1394H90.7047V31.0712Z" fill="#49454E" />
      <path
        d="M102.09 21.9242H99.7877V28.4593C99.7877 30.2077 98.7856 31.359 97.1225 31.359C95.534 31.359 94.7238 30.4422 94.7238 28.7365V21.9242H92.4211V29.1842C92.4211 31.7535 93.9136 33.342 96.3549 33.342C98.0713 33.342 99.212 32.585 99.8303 31.2418H99.8836V33.1394H102.09V21.9242Z"
        fill="#49454E"
      />
      <path
        d="M104.376 33.1394H106.679V26.6043C106.679 24.8666 107.724 23.7153 109.344 23.7153C110.943 23.7153 111.732 24.6427 111.732 26.3271V33.1394H114.046V25.8794C114.046 23.3101 112.628 21.7323 110.154 21.7323C108.427 21.7323 107.233 22.5212 106.626 23.8005H106.572V21.9242H104.376V33.1394Z"
        fill="#49454E"
      />
      <path
        d="M120.201 31.5083C118.965 31.5083 118.154 30.8687 118.154 29.8879C118.154 28.9284 118.933 28.3207 120.308 28.2248L123.069 28.0542V28.939C123.069 30.3996 121.811 31.5083 120.201 31.5083ZM119.562 33.3313C121.033 33.3313 122.451 32.5424 123.101 31.2951H123.154V33.1394H125.361V25.4103C125.361 23.1609 123.623 21.7217 120.905 21.7217C118.133 21.7217 116.406 23.2142 116.278 25.2397H118.453C118.655 24.2376 119.487 23.598 120.82 23.598C122.227 23.598 123.069 24.3442 123.069 25.5916V26.4551L119.999 26.6363C117.333 26.7856 115.83 27.9902 115.83 29.9518C115.83 31.9667 117.365 33.3313 119.562 33.3313Z"
        fill="#49454E"
      />
      <path d="M81.1526 59.8791H93.6907V56.899H84.6957V40.0625H81.1526V59.8791Z" fill="#49454E" />
      <path
        d="M102.471 60.1675C106.742 60.1675 109.516 57.3248 109.516 52.6006V52.5732C109.516 47.8765 106.7 45.0201 102.457 45.0201C98.2273 45.0201 95.4258 47.904 95.4258 52.5732V52.6006C95.4258 57.311 98.1861 60.1675 102.471 60.1675ZM102.484 57.4072C100.301 57.4072 98.9139 55.6631 98.9139 52.6006V52.5732C98.9139 49.5519 100.328 47.7941 102.457 47.7941C104.627 47.7941 106.028 49.5382 106.028 52.5732V52.6006C106.028 55.6494 104.641 57.4072 102.484 57.4072Z"
        fill="#49454E"
      />
      <path
        d="M118.557 60.1675C122.237 60.1675 124.695 57.8604 124.956 54.8254L124.97 54.7292H121.743L121.715 54.8528C121.399 56.3772 120.301 57.4072 118.57 57.4072C116.414 57.4072 115.014 55.6494 115.014 52.6144V52.6006C115.014 49.6206 116.401 47.7941 118.557 47.7941C120.369 47.7941 121.413 48.934 121.701 50.3484L121.729 50.472H124.956L124.942 50.3622C124.736 47.4508 122.347 45.0201 118.515 45.0201C114.272 45.0201 111.525 47.9315 111.525 52.5732V52.5869C111.525 57.2561 114.231 60.1675 118.557 60.1675Z"
        fill="#49454E"
      />
      <path
        d="M131.731 60.1263C133.654 60.1263 135.274 59.165 136.112 57.6406H136.194V59.8791H139.614V49.8678C139.614 46.9427 137.266 45.0201 133.626 45.0201C129.932 45.0201 127.694 46.9564 127.488 49.5382L127.474 49.703H130.646L130.674 49.5794C130.893 48.5082 131.882 47.7529 133.503 47.7529C135.233 47.7529 136.194 48.6456 136.194 50.115V51.1175L132.39 51.351C128.861 51.5707 126.856 53.1225 126.856 55.7043V55.7318C126.856 58.3273 128.82 60.1263 131.731 60.1263ZM130.262 55.5944V55.5807C130.262 54.4271 131.182 53.6993 132.871 53.5894L136.194 53.3834V54.4821C136.194 56.2124 134.711 57.517 132.747 57.517C131.251 57.517 130.262 56.7892 130.262 55.5944Z"
        fill="#49454E"
      />
      <path
        d="M148.202 60.1675C148.888 60.1675 149.493 60.0988 149.891 60.0439V57.4758C149.657 57.4896 149.396 57.5308 149.067 57.5308C147.817 57.5308 147.185 57.0776 147.185 55.6494V47.9589H149.891V45.3222H147.185V41.6281H143.711V45.3222H141.651V47.9589H143.711V55.9377C143.711 58.9315 145.153 60.1675 148.202 60.1675Z"
        fill="#49454E"
      />
      <path
        d="M158.781 60.1675C162.585 60.1675 164.727 57.9153 165.18 55.6768L165.221 55.5258H162.022L161.994 55.6219C161.665 56.5969 160.552 57.5033 158.849 57.5033C156.625 57.5033 155.224 55.9927 155.169 53.4384H165.359V52.3397C165.359 47.9452 162.777 45.0201 158.616 45.0201C154.455 45.0201 151.777 48.0413 151.777 52.6281V52.6418C151.777 57.2698 154.414 60.1675 158.781 60.1675ZM158.657 47.698C160.47 47.698 161.788 48.8516 162.035 51.1587H155.21C155.485 48.9202 156.844 47.698 158.657 47.698Z"
        fill="#49454E"
      />
    </g>
    <defs>
      <clipPath id="clip0_11111_142358">
        <rect width="165.361" height="80" fill="white" transform="translate(0.319336)" />
      </clipPath>
      <clipPath id="clip1_11111_142358">
        <rect width="72.5" height="54.5833" fill="white" transform="translate(0.319336 12.709)" />
      </clipPath>
    </defs>
  </svg>
);
