import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { useMemo } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

export const AppNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = useMemo(() => {
    if (
      matchPath("/dashboard", location.pathname) !== null ||
      matchPath("/", location.pathname) !== null
    ) {
      return "/dashboard";
    }

    if (
      matchPath("/trackers/*", location.pathname) !== null ||
      matchPath("/bulkTrackerEdit/*", location.pathname) !== null
    ) {
      return "/trackers";
    }

    return false;
  }, [location.pathname]);

  const onTabClick = (path: string) => {
    navigate(path);
  };

  return (
    <Tabs value={currentPath}>
      <Tab
        value="/dashboard"
        label="Dashboard"
        onClick={() => onTabClick("/dashboard")}
        data-testid="dashboardTab"
      />
      <Tab
        value="/trackers"
        label="Trackers"
        onClick={() => onTabClick("/trackers")}
        data-testid="trackersTab"
      />
    </Tabs>
  );
};
