export const commandStatuses = ["expired", "waiting", "success"] as const;
type CommandStatus = typeof commandStatuses[number];

export interface Command {
  cid: string;
  id: string;
  msgId: number;
  status: CommandStatus;
  retries: number;
  created: number;
  updated: number;
  duration: number;
  latency: number;
  sent: number;
  tid: string;
  path: string;
  code: number;
  mct: number;
  replies: { created: number; msgId: number; token: number; updated: number }[];
  responseReceived: number | null;
}

export type CommandResponse = Omit<Command, "id">;
