import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  title: string;
}

export const EditPageLayout = ({ children, title }: Props) => {
  return (
    <Box sx={{ maxWidth: "730px", width: "100%" }}>
      <Typography variant="h4" gutterBottom sx={{ paddingTop: "15px" }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};
