import EditIcon from "@mui/icons-material/Edit";
import TungstenIcon from "@mui/icons-material/Tungsten";
import IconButton from "@mui/material/IconButton";
import { useGridApiContext } from "@mui/x-data-grid-pro";

import { useMemo } from "react";

import { useCreatePath, useResourceContext } from "react-admin";
import { createSearchParams, useNavigate } from "react-router-dom";

import { useDialog } from "common";

import { BulkDeleteTrackers } from "./components/BulkDeleteTrackers";
import { usePermissionsContext } from "../../../../../../../auth";
import { toolbarButtonSize } from "../../../../../../../components/ListDataGrid/components/ListDataGridToolbar/ListDataGridToolbar.styled";

import { DeviceAlertDialog } from "../../../../../components/DeviceAlertDialog";

export const TrackersTableSelectedRowsActions = () => {
  const resource = useResourceContext();
  const apiRef = useGridApiContext();
  const selectedRowsMap = apiRef.current.getSelectedRows();
  const selectedRows = Array.from(selectedRowsMap.values());
  const selectedIds = Array.from(selectedRowsMap.keys());

  const createPath = useCreatePath();
  const navigate = useNavigate();

  const { canEdit } = usePermissionsContext();

  const onEdit = () => {
    if (selectedIds.length === 0) return;

    if (selectedIds.length === 1) {
      const link = createPath({
        type: "edit",
        resource: resource,
        id: selectedIds[0]
      });
      navigate(link);
    }

    if (selectedIds.length > 1) {
      const searchParams = new URLSearchParams();
      selectedIds.forEach((id) => searchParams.append("trackerId", id as string));

      navigate({
        pathname: "/bulkTrackerEdit",
        search: createSearchParams(searchParams).toString()
      });
    }
  };

  const onDeleteSuccess = () => {
    apiRef.current.selectRows([], false, true);
  };

  const isAnyRowSelected = selectedRowsMap.size > 0;

  const areAllTrackersInDeletingState = useMemo(
    () => selectedRows.every((tracker) => tracker?.state === "Deleting..."),
    [selectedRows]
  );

  const {
    isOpen: isIdentifyTrackerDialogOpen,
    handleClose: handleCloseIdentifyTrackerDialog,
    handleOpen: handleOpenIdentifyTrackerDialog
  } = useDialog();

  return canEdit && isAnyRowSelected ? (
    <>
      <BulkDeleteTrackers
        selectedIds={selectedIds}
        forceDelete={areAllTrackersInDeletingState}
        onDeleteSuccess={onDeleteSuccess}
      />
      <IconButton
        onClick={handleOpenIdentifyTrackerDialog}
        title="Identify trackers"
        size="small"
        color="primary"
        sx={{ ...toolbarButtonSize }}
      >
        <TungstenIcon sx={{ rotate: "180deg" }} />
      </IconButton>
      <IconButton
        onClick={onEdit}
        data-testid="editTrackersButton"
        title="Edit"
        size="small"
        color="primary"
        sx={{ ...toolbarButtonSize }}
      >
        <EditIcon />
      </IconButton>
      <DeviceAlertDialog
        selectedIds={selectedIds}
        handleClose={handleCloseIdentifyTrackerDialog}
        isOpen={isIdentifyTrackerDialogOpen}
      />
    </>
  ) : null;
};
