import {
  CreateResult,
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateParams,
  UpdateResult
} from "react-admin";

import { getFilteredUserList } from "./helpers/getFilteredUserList";
import { mapUserResponse } from "./helpers/mapUserResponse";
import { mapUserToEditRequestBody } from "./helpers/mapUserToEditRequestBody";
import { User, UserResponse } from "./types";
import { httpClient } from "../../services";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "users";

const USERS_API = `${API_URL}/${RESOURCE_NAME}`;
const USER_POOL_ID = process.env.REACT_APP_AUTH_USER_POOL_ID;

const MOBILE_ADMIN_USER_EMAIL = "1234567890@example.com";

export const usersDataProvider: DataProvider = {
  getList: async (_, params: GetListParams): Promise<GetListResult> => {
    const userData: User[] = await httpClient(USERS_API).then(({ json }) =>
      json
        .filter(
          ({ userPoolType }: { userPoolType?: "App" | "Console" | "Unknown" }) =>
            userPoolType === "Console"
        )
        .map(mapUserResponse)
        .filter(
          (user: User) =>
            // It happens on edge that some users don't have emails
            // It is not consistent with the contract, so they're not displayed in the table
            user.email !== undefined && user.email !== MOBILE_ADMIN_USER_EMAIL
        )
    );

    const filteredUsers = getFilteredUserList(userData, { query: params.filter.query });

    return {
      data: filteredUsers,
      total: filteredUsers.length
    };
  },

  getOne: async (_, params: GetOneParams): Promise<GetOneResult> => {
    const user: User = await httpClient(
      `${USERS_API}/${encodeURIComponent(`con:${params.id}`)}`
    ).then(({ json }) => mapUserResponse(json));

    if (!user) throw new Error("The user with provided phone number doesn't exist");

    return { data: user };
  },

  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => {
    const users: User[] = await httpClient(USERS_API).then(({ json }) =>
      json.map((userResponse: UserResponse) => mapUserResponse(userResponse))
    );

    return { data: users };
  },

  create: (_, params): Promise<CreateResult> =>
    httpClient(USERS_API, {
      method: "POST",
      body: JSON.stringify({ ...params.data, userPoolId: USER_POOL_ID })
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    })),

  delete: (_, params) =>
    httpClient(
      `${USERS_API}/${encodeURIComponent(`con:${params.id}`)}
    `,
      {
        method: "DELETE"
      }
    ).then(({ json }) => ({ data: json })),

  update: async (_, params: UpdateParams<User>): Promise<UpdateResult> =>
    httpClient(`${API_URL}/users/${encodeURIComponent(`con:${params.id}`)}`, {
      method: "PUT",
      body: JSON.stringify(mapUserToEditRequestBody(params.data as User))
    }).then(() => {
      // The API response doesn't contain all the user data
      return { data: params.data };
    }),

  // TODO not implemented yet
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  deleteMany: async (_, _params) => Promise.resolve({})
};
