import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const UserMenuContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;

  cursor: pointer;
  
  margin-left: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
`
);

export const UserDataContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const UserInitialsContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: #BDBDBD;
  width: 40px;
  height: 40px;
  color: #FFFFFF;
  
  margin-right: ${theme.spacing(1)}; 
`
);
