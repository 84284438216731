import { useTheme } from "react-admin";

import { isThemeType } from "../isThemeType";

export const useLayoutMode = () => {
  const [theme] = useTheme();

  const mode = isThemeType(theme) ? theme : theme?.palette?.mode;

  return mode ?? "light";
};
