import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  value: number | string;
  index: number | string;
  name: string;
  "data-testid"?: string;
}

export const TabPanel = ({ children, value, index, name, "data-testid": testid }: Props) => {
  const isActive = value === index;

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-panel-${name}`}
      hidden={!isActive}
      data-testid={testid}
    >
      {isActive ? children : null}
    </div>
  );
};
