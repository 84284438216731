import { forwardRef } from "react";
import { useInput } from "react-admin";

import { TagsField } from "./TagsField";

interface Props {
  source: string;
  label?: string;
}

export const TagsFieldWrapper = forwardRef(({ source, ...props }: Props, ref) => {
  const { field } = useInput({ source });

  return <TagsField {...props} {...field} ref={ref} />;
});
