import { v4 as uuidv4 } from "uuid";

import type { WSNotificationResponse, Notification } from "../../types";

export const mapWSNotificationToNotification = (
  wsNotification: WSNotificationResponse
): Notification => ({
  ...wsNotification,
  id: uuidv4(),
  loc: wsNotification.loc
    ? {
        // Due to the bug, agent sends location data sometimes as strings, sometimes as numbers.
        // We need to parse them to numbers.
        lon: parseFloat(wsNotification.loc[0] as unknown as string),
        lat: parseFloat(wsNotification.loc[1] as unknown as string)
      }
    : null,
  received: (wsNotification.time + wsNotification.age) * 1000,
  time: wsNotification.time * 1000,
  gatewayLatency: wsNotification.age - wsNotification.lag,
  timeGatewayOut: (wsNotification.time + wsNotification.age - wsNotification.lag) * 1000
});
