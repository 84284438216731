import { orderBy } from "lodash-es";
import { SORT_DESC, SortPayload } from "react-admin";

import { Tracker } from "../../types";

const getSortField = (tracker: Tracker, field: string) => {
  const sortField = tracker[field as keyof Tracker];

  return typeof sortField === "string" ? sortField.toLowerCase() : sortField;
};

export const getSortedTrackersList = (trackers: Tracker[], sort: SortPayload) =>
  orderBy(
    trackers,
    [(tracker) => getSortField(tracker, sort.field)],
    [sort.order === SORT_DESC ? "desc" : "asc"]
  );
