import Chip from "@mui/material/Chip";
import { styled, Theme } from "@mui/material/styles";

import { TrackerState } from "types";

const getColorByState = (state: TrackerState, theme: Theme) => {
  const colorsByState: {
    [key in TrackerState]: { backgroundColor: string; color: string };
  } = {
    "Securing...": {
      backgroundColor: "rgba(237, 108, 2, 0.12);",
      color: theme.palette.warning.main
    },
    Secured: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText
    },
    "Deleting...": {
      backgroundColor: "rgba(156, 39, 176, 0.12);",
      color: "#AB47BC"
    }
  };

  return colorsByState[state];
};

export const StateChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "trackerState"
})<{ trackerState: TrackerState }>(({ theme, trackerState }) => {
  return getColorByState(trackerState, theme);
});
