import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { forwardRef, SyntheticEvent } from "react";
import { ControllerRenderProps } from "react-hook-form";

export interface Props extends ControllerRenderProps {
  label?: string;
  helperText?: string;
  acceptsNewTags?: boolean;
}

export const TagsField = forwardRef(
  ({ label, helperText, acceptsNewTags = true, ...props }: Props, ref) => {
    const onChange = (event: SyntheticEvent, newValue: string[]) => {
      props.onChange(newValue);
    };

    return (
      <Autocomplete
        {...props}
        multiple
        options={[]}
        fullWidth
        freeSolo
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" helperText={helperText} />
        )}
        ref={ref}
        inputValue={acceptsNewTags ? undefined : ""}
      />
    );
  }
);
