export const APP_NAME = "LUNA XIO";

export const ROWS_PER_PAGE = 20;
export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50];

export const DEFAULT_STALE_TIME = 60000;
export const TABLE_REFETCH_INTERVAL = 60000;

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const DEFAULT_MOCKED_TRACKER_ID = ENVIRONMENT ? `${ENVIRONMENT}-1` : "";
