import Typography from "@mui/material/Typography";

import { useAutoLogin } from "./hooks/useAutoLogin";
import * as S from "./styled";
import { LogoWithText } from "../Logo";

interface LoginScreenHeaderProps {
  title: string;
  agentName?: string;
}
export const LoginScreenHeader = ({ title, agentName }: LoginScreenHeaderProps) => {
  useAutoLogin();

  return (
    // Not using AWS-Amplify components, as they require additional setup, e.g. for tests (e.g. mock of window.URL.createObjectURL)
    // and throw a warning about XState configuration (predictableActionArgument)
    <S.HeaderContainer>
      <S.LogoAndWelcomeTextContainer>
        <LogoWithText />
        <S.HeaderText variant="h5">{title}</S.HeaderText>
      </S.LogoAndWelcomeTextContainer>

      {agentName && (
        <S.AgentNameContainer>
          <Typography variant="overline" color="#00000099">
            Agent
          </Typography>

          <Typography variant="subtitle1">{agentName}</Typography>
        </S.AgentNameContainer>
      )}
    </S.HeaderContainer>
  );
};
