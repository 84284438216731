import { fetchUtils, Options } from "react-admin";

import { getAccessToken } from "../../auth";

export const httpClient = async (url: string, options: Options = {}) => {
  const token = await getAccessToken();

  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };

  return fetchUtils.fetchJson(url, options);
};
