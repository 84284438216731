import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { useCreatePath } from "react-admin";
import { Popup } from "react-map-gl";
import { useNavigate } from "react-router-dom";

import { Tracker } from "../../../../dataProvider";

interface Props {
  tracker: Tracker;
  onClose: () => void;
}

export const TrackersMapPopup = ({ tracker, onClose }: Props) => {
  const navigate = useNavigate();
  const createPath = useCreatePath();

  const goToDetails = useCallback(() => {
    navigate(createPath({ resource: "trackers", type: "show", id: tracker.id }));
  }, [createPath, navigate, tracker.id]);

  if (!tracker.location) {
    return null;
  }

  return (
    <Popup
      anchor="top"
      longitude={tracker.location.lon}
      latitude={tracker.location.lat}
      onClose={onClose}
      offset={10}
    >
      <Box sx={{ p: 2 }}>
        <Typography>Tracker: {tracker.name}</Typography>
        <Button
          sx={{ mt: 1 }}
          onClick={goToDetails}
          variant="outlined"
          endIcon={<ArrowForwardIcon />}
        >
          Show details
        </Button>
      </Box>
    </Popup>
  );
};
