import MuiFormControl from "@mui/material/FormControl";
import MuiFormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import MuiToggleButton from "@mui/material/ToggleButton";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const ToggleFormControl = styled(MuiFormControl)`
  flex-direction: row;
`;

export const ToggleFormLabel = styled(MuiFormLabel)(({ theme }) => ({
  marginRight: theme.spacing(2.5),
  paddingTop: theme.spacing(2),
  color: theme.palette.text.primary
}));

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  gap: theme.spacing(0.75),

  "& .MuiToggleButtonGroup-grouped": {
    border: `1px solid`,
    margin: theme.spacing(0.5),

    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[300],

      "&.Mui-selected": {
        border: `1px solid ${theme.palette.primary.main}`
      }
    },

    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[300],

      "&.Mui-selected": {
        border: `1px solid ${theme.palette.primary.main}`
      }
    }
  }
}));

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.text.primary,

  "&.Mui-selected": {
    color: theme.palette.text.primary,
    borderColor: theme.palette.primary.main
  }
}));
