import { styled } from "@mui/material/styles";

export const HeaderContainer = styled("div")(
  ({ theme }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${theme.spacing(2)};
`
);
