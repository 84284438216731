import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useCreatePath } from "react-admin";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { getUserInitials } from "common";
import { ToggleThemeButton } from "components";

import * as S from "./UserMenu.styled";
import { usePermissionsContext } from "../../../../auth";
import { getUserRoleLabel } from "../../../../common";

export const UserMenu = () => {
  const navigate = useNavigate();
  const createPath = useCreatePath();
  const { user, canAdmin } = usePermissionsContext();
  const queryClient = useQueryClient();

  const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorElement);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorElement(null);
  };

  const openUsersManagement = () => {
    navigate(createPath({ type: "list", resource: "users" }));
    handleClose();
  };

  const logout = () => {
    queryClient.clear();
    Auth.signOut();
  };

  const userInitials = user ? getUserInitials(user.name) : "";

  return (
    <>
      <ToggleThemeButton />

      <S.UserMenuContainer onClick={handleOpen}>
        <S.UserInitialsContainer data-testid="AccountInitials">
          <Typography>{userInitials}</Typography>
        </S.UserInitialsContainer>
        <S.UserDataContainer>
          <Typography>{user?.name}</Typography>
          <Typography variant="body2" color="text.secondary" data-testid="userRole">
            {user?.role && getUserRoleLabel(user?.role)}
          </Typography>
        </S.UserDataContainer>
      </S.UserMenuContainer>

      <Menu
        anchorEl={menuAnchorElement}
        open={isMenuOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 180
          }
        }}
      >
        {canAdmin ? (
          <MenuItem onClick={openUsersManagement} data-testid="userManagementButton">
            User management
          </MenuItem>
        ) : null}
        <MenuItem onClick={logout} data-testid="logoutButton">
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
