import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateResult
} from "react-admin";

import { mapSensorDataResponse } from "./helpers/mapSensorDataResponse";
import { SensorData } from "./types";
import { httpClient } from "../../services";

import { TRACKERS_FILTER_KEY } from "../const";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "sensors";

const SENSOR_DATA_API = `${API_URL}/${RESOURCE_NAME}`;

export const sensorDataDataProvider: DataProvider = {
  getList: async (_, params: GetListParams): Promise<GetListResult> => {
    const searchParams = new URLSearchParams({
      tid: params.filter[TRACKERS_FILTER_KEY]
    });

    const sensorData: SensorData[] = await httpClient(
      `${SENSOR_DATA_API}?${searchParams.toString()}`
    ).then(({ json }) => mapSensorDataResponse(json));

    return {
      data: sensorData,
      total: sensorData.length
    };
  },

  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => {
    const commands: SensorData[] = await httpClient(SENSOR_DATA_API).then(({ json }) => json);

    return {
      data: commands
    };
  },

  // TODO not implemented yet
  getOne: async (_, _params: GetOneParams): Promise<GetOneResult> => Promise.resolve({} as any),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  create: (_, _params) => Promise.resolve({ data: {} as any }),
  update: (_, _params): Promise<UpdateResult> => Promise.resolve({ data: {} as any }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  delete: (_, _params) => Promise.resolve({ data: {} as any }),
  deleteMany: async (_, _params) => Promise.resolve({})
};
