import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const ToolbarContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const SearchAndFilterContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center; 
  gap: ${theme.spacing(2)};
  
  flex: 1;
`
);

export const ActionsContainer = styled(Box)`
  display: flex;
  gap: 2px;
`;

export const toolbarButtonSize = {
  width: "40px",
  height: "40px"
};
