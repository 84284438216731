import { User } from "../../dataProvider";

const SUPER_ADMIN_EMAILS = process.env.REACT_APP_SUPER_ADMIN_EMAIL_DOMAINS;
const SUPER_ADMIN_EMAILS_LIST = SUPER_ADMIN_EMAILS?.split(",");

export const getCurrentUserPermissions = (currentUser?: User) => {
  const role = currentUser?.role;

  const canSuperAdmin = currentUser
    ? SUPER_ADMIN_EMAILS_LIST?.some((adminEmailDomain) =>
        currentUser.email.endsWith(adminEmailDomain)
      ) ?? false
    : false;

  return role
    ? {
        canAdmin: role === "admin",
        canEdit: role === "writer" || role === "admin",
        canSuperAdmin: canSuperAdmin
      }
    : { canAdmin: false, canEdit: false, canSuperAdmin: false };
};
