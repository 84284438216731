import BluetoothIcon from "@mui/icons-material/Bluetooth";
import PeopleIcon from "@mui/icons-material/People";
import { LicenseInfo } from "@mui/x-license";
import { lazy } from "react";
import { Admin, CustomRoutes, RaThemeOptions, Resource } from "react-admin";
import { Route } from "react-router-dom";

import { suspended } from "components";
import { darkTheme, theme } from "theme";

import { usePermissionsContext } from "../auth";
import { AppLayout } from "../components";
import { APP_NAME } from "../consts";
import { dataProvider } from "../dataProvider";

import { queryClient } from "../services";

const TrackerList = lazy(async () => import("./Trackers/TrackerList"));
const TrackerShow = lazy(async () => import("./Trackers/TrackerShow"));
const TrackerCreate = lazy(async () => import("./Trackers/TrackerCreate"));
const TrackerEdit = lazy(async () => import("./Trackers/TrackerEdit"));
const TrackerBulkEdit = lazy(async () => import("./Trackers/TrackerBulkEdit"));

const UserList = lazy(async () => import("./Users/UserList"));
const UserCreate = lazy(async () => import("./Users/UserCreate"));
const UserEdit = lazy(async () => import("./Users/UserEdit"));

const Dashboard = lazy(async () => import("./Dashboard"));

const MUI_PRO_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY;
LicenseInfo.setLicenseKey(MUI_PRO_LICENSE_KEY ?? "");

export const LunaApp = () => {
  const { canAdmin, canEdit } = usePermissionsContext();

  return (
    <Admin
      dataProvider={dataProvider}
      title={APP_NAME}
      lightTheme={theme as RaThemeOptions}
      darkTheme={darkTheme as RaThemeOptions}
      layout={AppLayout}
      queryClient={queryClient}
      dashboard={Dashboard}
    >
      <CustomRoutes>
        <Route path="dashboard" element={suspended(<Dashboard />)} />
      </CustomRoutes>

      <Resource
        name="trackers"
        list={suspended(<TrackerList />)}
        show={suspended(<TrackerShow />)}
        create={canEdit ? suspended(<TrackerCreate />) : undefined}
        edit={canEdit ? suspended(<TrackerEdit />) : undefined}
        icon={BluetoothIcon}
      />
      <CustomRoutes>
        <Route path="bulkTrackerEdit" element={suspended(<TrackerBulkEdit />)} />
      </CustomRoutes>

      {canAdmin ? (
        <Resource
          name="users"
          list={suspended(<UserList />)}
          create={suspended(<UserCreate />)}
          edit={suspended(<UserEdit />)}
          icon={PeopleIcon}
        />
      ) : null}
    </Admin>
  );
};
