import { useQuery, UseQueryResult } from "react-query";

import { assertIsAgentInfoData } from "./guards/assertIsAgentInfoData";

import { agentInfo } from "./queryKeys";
import { AgentInfoData } from "./types";
import { httpClient } from "../../httpClient";

const API_URL = process.env.REACT_APP_API_URL;

export const useAgentInfo = (): UseQueryResult<AgentInfoData> =>
  useQuery(agentInfo(), () =>
    httpClient(`${API_URL}/info/agent`).then(({ json }) => {
      assertIsAgentInfoData(json);
      return json;
    })
  );
