import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import CircleIcon from "@mui/icons-material/Circle";
import Chip from "@mui/material/Chip";
import { GridColDef } from "@mui/x-data-grid-pro";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { formatDate, lowBatteryFilterOperators } from "common";

import { ShowMoreOverflow, StateChip } from "components";
import { Tracker, trackerStates } from "types";

const TABLE_CELL_PADDING = 10;

export const trackersColumnDefinitions: GridColDef<Tracker>[] = [
  {
    field: "tid",
    headerName: "Internal ID",
    description: "Unique ID of tracker",
    minWidth: 150,
    flex: 1
  },
  {
    field: "name",
    headerName: "Name",
    description: "Name of tracker",
    minWidth: 150,
    flex: 1
  },
  {
    field: "tags",
    headerName: "Tags",
    description: "Identification tags",
    minWidth: 180,
    sortable: false,
    renderCell: ({ value: tags, colDef }) => {
      return (
        <ShowMoreOverflow
          containerWidth={(colDef.width ?? 220) - 2 * TABLE_CELL_PADDING}
          showMoreTooltipContent={tags?.join(", ")}
        >
          {tags?.map((tag: string) => (
            <Chip key={tag} label={tag} sx={{ mr: "2px" }} size="small" />
          ))}
        </ShowMoreOverflow>
      );
    },
    flex: 1
  },
  {
    field: "state",
    headerName: "State",
    description: "Tracker activation state",
    minWidth: 120,
    renderCell: ({ value }) => <StateChip label={value} trackerState={value} size="small" />,
    type: "singleSelect",
    valueOptions: [...trackerStates]
  },
  {
    field: "created",
    headerName: "Provision time",
    description: "Time tracker added to the system",
    valueGetter: (value) => (value ? new Date(value) : null),
    valueFormatter: (value) => (value ? formatDate(value) : null),
    minWidth: 180,
    type: "dateTime"
  },
  {
    field: "secured",
    headerName: "Secure time",
    description: "Time device secured",
    valueGetter: (value) => (value ? new Date(value) : null),
    valueFormatter: (value) => (value ? formatDate(value) : null),
    minWidth: 180,
    type: "dateTime"
  },
  {
    field: "status.lowBatt",
    headerName: "Battery",
    description: "Low battery indicator",
    minWidth: 50,
    valueGetter: (_, row) => row.status?.lowBatt,
    renderCell: ({ row }) =>
      row.status?.lowBatt ? (
        <Battery1BarIcon htmlColor="#9F0000" sx={{ transform: "rotate(90deg)" }} />
      ) : (
        <span />
      ),
    type: "boolean",
    filterOperators: lowBatteryFilterOperators
  },
  {
    field: "device.firmware",
    headerName: "FW version",
    description: "Firmware version",
    valueGetter: (_, row) => row.device?.firmware,
    minWidth: 150
  },
  {
    field: "device.manufacturer",
    headerName: "HW version",
    description: "Hardware version",
    valueGetter: (_, row) => row.device?.manufacturer,
    minWidth: 150
  },
  {
    field: "device.k",
    headerName: "EID period",
    description: "Time period between EID changes",
    minWidth: 50,
    valueGetter: (_, row) => row.device?.k,
    type: "number"
  },
  {
    field: "recency",
    headerName: "Last seen (s)",
    description: "How long since latest notification received from device",
    renderCell: ({ row }) => {
      if (row.lastSeen) {
        return formatDistanceToNow(row.lastSeen, { includeSeconds: true });
      }
      return null;
    },
    minWidth: 170
  },
  {
    field: "lastSeen",
    headerName: "Time last seen",
    description: "Time latest notification received from device",
    valueGetter: (value) => (value ? new Date(value) : null),
    valueFormatter: (value) => (value ? formatDate(value) : null),
    minWidth: 180,
    type: "dateTime"
  },
  {
    field: "gateway.name",
    headerName: "Last seen gateway",
    description: "Name of gateway sending latest notification",
    valueGetter: (_, row) => row.gateway?.name,
    minWidth: 180
  },
  {
    field: "isPresent",
    headerName: "Present",
    description: "Is device in the presence of a gateway?",
    renderCell: ({ value }) => {
      return value ? (
        <CircleIcon
          color="primary"
          sx={{
            fontSize: 10
          }}
        />
      ) : (
        <span />
      );
    },
    minWidth: 100,
    type: "boolean"
  },
  {
    field: "latency",
    headerName: "Latency (s)",
    minWidth: 100,
    description: "Time for latest device notification to reach agent",
    valueFormatter: (value) => (value !== undefined ? `${value}s` : null)
  },
  {
    field: "gwLocation.lon",
    headerName: "Gateway longitude",
    description: "Longitude from gateway",
    valueGetter: (_, row) => row.gwLocation?.lon,
    valueFormatter: (value: number) => value?.toFixed(6),
    minWidth: 160
  },
  {
    field: "gwLocation.lat",
    headerName: "Gateway latitude",
    description: "Latitude from gateway",
    valueGetter: (_, row) => row.gwLocation?.lat,
    valueFormatter: (value: number) => value?.toFixed(6),
    minWidth: 160
  },
  {
    field: "gpsLocation.lon",
    headerName: "GPS longitude",
    description: "Longitude from GPS",
    valueGetter: (_, row) => row.gpsLocation?.lon,
    valueFormatter: (value: number) => value?.toFixed(6),
    minWidth: 160
  },
  {
    field: "gpsLocation.lat",
    headerName: "GPS latitude",
    description: "Latitude from GPS",
    valueGetter: (_, row) => row.gpsLocation?.lat,
    valueFormatter: (value: number) => value?.toFixed(6),
    minWidth: 160
  },
  {
    field: "namedLocation",
    headerName: "Named location",
    minWidth: 150,
    description: "Possible named locations for device, as configured as on gateways",
    valueGetter: (_, row) =>
      row.notifs
        ?.filter((notification) => notification.place)
        .map((notification) => notification.place)
        .join(", "),
    sortable: false
  }
];
