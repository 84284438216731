import type { LayerProps } from "react-map-gl";

export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": ["step", ["get", "point_count"], "#2e7d32", 3, "#ed6c02", 10, "#f28cb1"],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
  }
};

export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-size": 12
  }
};

export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "symbol",
  filter: ["!", ["has", "point_count"]],
  layout: {
    "icon-image": "map-pin",
    "text-field": "{id}",
    "text-size": 11,
    "text-offset": [0, 1.5],
    "icon-allow-overlap": true,
    "text-allow-overlap": true
  }
};
