import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
import { ChangeEvent, useEffect } from "react";

interface LabelledBooleanFilterInputProps extends GridFilterInputValueProps {
  options: string[];
}
const LabeledBooleanFilterInput = ({
  item,
  applyValue,
  options
}: LabelledBooleanFilterInputProps) => {
  const handleFilterChange = (event: ChangeEvent<HTMLSelectElement>) => {
    applyValue({ ...item, value: event.target.value });
  };

  useEffect(() => {
    if (!item.value) {
      applyValue({ ...item, value: options[0].toLowerCase() });
    }
  }, [applyValue, item, options]);

  return (
    <>
      <InputLabel>Value</InputLabel>
      <NativeSelect value={item.value} onChange={handleFilterChange}>
        {options.map((option) => (
          <option key={option} value={option.toLowerCase()}>
            {option}
          </option>
        ))}
      </NativeSelect>
    </>
  );
};

export default LabeledBooleanFilterInput;
