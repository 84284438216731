import { AgentInfoData } from "../../types";

const isAgentInfoData = (data: any): data is AgentInfoData =>
  typeof data === "object" &&
  typeof data.trackers === "number" &&
  typeof data.recency === "object" &&
  typeof data.recency.lastMin === "object" &&
  typeof data.recency.lastMin.total === "number" &&
  typeof data.recency.lastMin.lowBattery === "number" &&
  typeof data.recency.lastHr === "object" &&
  typeof data.recency.lastHr.total === "number" &&
  typeof data.recency.lastHr.lowBattery === "number" &&
  typeof data.recency.lastDay === "object" &&
  typeof data.recency.lastDay.total === "number" &&
  typeof data.recency.lastDay.lowBattery === "number" &&
  typeof data.recency.lastWeek === "object" &&
  typeof data.recency.lastWeek.total === "number" &&
  typeof data.recency.lastWeek.lowBattery === "number" &&
  typeof data.states === "object" &&
  typeof data.states.deleting === "object" &&
  typeof data.states.deleting.total === "number" &&
  typeof data.states.deleting.lowBattery === "number" &&
  typeof data.states.secured === "object" &&
  typeof data.states.secured.total === "number" &&
  typeof data.states.secured.lowBattery === "number" &&
  typeof data.states.securing === "object" &&
  typeof data.states.securing.total === "number" &&
  typeof data.states.securing.lowBattery === "number";

export function assertIsAgentInfoData(data: unknown): asserts data is AgentInfoData {
  if (!isAgentInfoData(data)) {
    throw new Error("Received invalid agent info response");
  }
}
