import AddIcon from "@mui/icons-material/Add";

import { useCreatePath, useResourceContext } from "react-admin";
import { useNavigate } from "react-router-dom";

import * as S from "./ListDataGridAddButton.styled";
import { usePermissionsContext } from "../../../../../../auth";
import { useListDataGridContext } from "../../../../context";
import { toolbarButtonSize } from "../../../ListDataGridToolbar/ListDataGridToolbar.styled";

export const ListDataGridAddButton = () => {
  const resource = useResourceContext();
  const createPath = useCreatePath();
  const navigate = useNavigate();

  const { hasCreatePage, onAddClick } = useListDataGridContext();
  const { canEdit } = usePermissionsContext();

  if (!hasCreatePage || !canEdit) {
    return null;
  }

  const onClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    onAddClick ? onAddClick(event) : navigate(createPath({ resource, type: "create" }));

  return (
    <S.AddButton
      onClick={onClick}
      size="small"
      title="Create"
      sx={{ ...toolbarButtonSize }}
      data-testid="createButton"
    >
      <AddIcon />
    </S.AddButton>
  );
};
