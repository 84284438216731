import CloudDoneIcon from "@mui/icons-material/CloudDone";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import Typography from "@mui/material/Typography";

export const DialogContentContainer = styled(Box)(
  ({ theme }) => `
    width: 400px;
    margin-top: ${theme.spacing(3)};
`
);

export const SpinnerContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

export const ImportError = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.error.dark};
    margin-top: ${theme.spacing(1)};
`
);
export const ImportSuccess = styled(CloudDoneIcon)(
  ({ theme }) => `
    color: ${theme.palette.success.contrastText};
    font-size: 5rem;
`
);
