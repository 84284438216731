import { Auth } from "aws-amplify";

import { confirmUser, User } from "../dataProvider";
import { mapUserResponse } from "../dataProvider";
import { httpClient } from "../services";

const API_URL = process.env.REACT_APP_API_URL;
const RESOURCE_NAME = "users";

const USERS_API = `${API_URL}/${RESOURCE_NAME}`;

const getUserId = async () => {
  const session = await Auth.currentSession();

  const idToken = session.getIdToken();
  const email: string | undefined = idToken.payload?.email;

  if (!email) {
    throw Error("Email number is missing in the ID Token");
  }

  return email;
};

export const getCurrentUser = async () => {
  const userId = await getUserId();

  const currentUser: User | null = await httpClient(
    `${USERS_API}/${encodeURIComponent(`con:${userId}`)}`
  ).then(({ json }) => (json ? mapUserResponse(json) : null));

  if (!currentUser) {
    throw new Error(`The user with id: ${userId} doesn't exist!`);
  }

  if (currentUser.status === "NEW") {
    confirmUser(currentUser);
  }

  return currentUser;
};
