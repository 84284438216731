import { GridFilterModel, GridPaginationModel, GridSortModel } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash-es";
import { useCallback, useMemo } from "react";
import { SORT_ASC, SORT_DESC, useListContext, SortPayload } from "react-admin";

import { Maybe } from "types";

interface UseServerSideModelsProps {
  disableColumnFilter: Maybe<boolean>;
  serverSideFilters: Maybe<boolean>;
  defaultSort: Maybe<SortPayload>;
}

export const useServerSideModelProps = ({
  disableColumnFilter,
  defaultSort,
  serverSideFilters
}: UseServerSideModelsProps) => {
  const { page, perPage, total, filterValues, setPage, setPerPage, setSort, setFilters } =
    useListContext();

  const paginationModel = useMemo(() => ({ page: page - 1, pageSize: perPage }), [page, perPage]);

  const filterModel = useMemo(() => {
    return isEmpty(filterValues)
      ? undefined
      : ({
          ...filterValues,
          items: filterValues?.items ?? [] // in case there is no items field on filterValues
        } as GridFilterModel);
  }, [filterValues]);

  const onPaginationModelChange = useCallback(
    (nextPaginationModel: GridPaginationModel) => {
      if (nextPaginationModel.pageSize === perPage) {
        setPage(nextPaginationModel.page + 1);
      } else {
        setPerPage(nextPaginationModel.pageSize);
      }
    },
    [perPage, setPage, setPerPage]
  );

  const onSortModelChange = useCallback(
    ([currentSort]: GridSortModel) => {
      if (currentSort) {
        const { field, sort } = currentSort;
        const sortOrder = sort === "asc" ? SORT_ASC : SORT_DESC;

        setSort({ field: field, order: sortOrder });
      } else {
        setSort(defaultSort ?? { field: "id", order: SORT_ASC });
      }
    },
    [defaultSort, setSort]
  );

  const onFilterModelChange = useCallback(
    (filter: GridFilterModel) => {
      setFilters(filter, [], true);
    },
    [setFilters]
  );

  const filtersState = useMemo<"disabled" | "client-side" | "server-side">(() => {
    if (disableColumnFilter) return "disabled";
    if (serverSideFilters) return "server-side";

    return "client-side";
  }, [disableColumnFilter, serverSideFilters]);

  return {
    onSortModelChange,

    filterModel,
    filtersState,
    onFilterModelChange,

    rowCount: total ?? 0,
    paginationModel,
    onPaginationModelChange
  };
};
