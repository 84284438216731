import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const PaginationContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NumberAndActionsContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing(2)}
`
);
