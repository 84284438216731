import { useContext } from "react";

import { ListDataGridContext } from "./context";

export const useListDataGridContext = () => {
  const context = useContext(ListDataGridContext);

  if (context === undefined) {
    throw new Error("useListDataGridContext must be used within a provider");
  }

  return context;
};
