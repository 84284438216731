import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import {
  gridFilterModelSelector,
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid-pro";
import { useCallback } from "react";

import { toolbarButtonSize } from "../ListDataGridToolbar/ListDataGridToolbar.styled";

export const ListDataGridFilterIcon = () => {
  const apiRef = useGridApiContext();
  const { items } = gridFilterModelSelector(apiRef);

  const { open, openedPanelValue } = useGridSelector(apiRef, gridPreferencePanelStateSelector);

  const handleClick = useCallback(() => {
    if (open && openedPanelValue === GridPreferencePanelsValue.filters) {
      apiRef.current.hideFilterPanel();
    } else {
      apiRef.current.showFilterPanel();
    }
  }, [apiRef, open, openedPanelValue]);

  return (
    <IconButton
      onClick={handleClick}
      size="small"
      title="Filter"
      color="primary"
      sx={{ ...toolbarButtonSize }}
    >
      <Badge badgeContent={items.length} color="primary">
        <FilterAltIcon />
      </Badge>
    </IconButton>
  );
};
