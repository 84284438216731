import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import { Identifier } from "react-admin";

import { useDialog } from "common";

import { toolbarButtonSize } from "../../../../../../../../../components/ListDataGrid/components/ListDataGridToolbar/ListDataGridToolbar.styled";

import { DeleteTrackersDialog } from "../../../../../../../components/DeleteTrackersDialog";

interface Props {
  selectedIds: Identifier[];
  forceDelete: boolean;
  onDeleteSuccess: () => void;
}

export const BulkDeleteTrackers = ({ selectedIds, forceDelete, onDeleteSuccess }: Props) => {
  const { isOpen, handleOpen, handleClose } = useDialog();

  const handleDeleteSuccess = () => {
    onDeleteSuccess();
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        size="small"
        color="error"
        data-testid="deleteTrackersButton"
        title="Delete"
        sx={{ ...toolbarButtonSize }}
      >
        <DeleteIcon />
      </IconButton>
      <DeleteTrackersDialog
        selectedIds={selectedIds}
        isOpen={isOpen}
        handleClose={handleClose}
        handleDeleteSuccess={handleDeleteSuccess}
        forceDelete={forceDelete}
      />
    </>
  );
};
