export const isDuplicatedError = (error: PromiseRejectedResult) => {
  const errorMessage = error.reason?.body?.error;

  return (
    typeof errorMessage === "string" &&
    (errorMessage as string).startsWith(
      "A Tracker with this masterKey already exists"
    )
  );
};
