import { v4 as uuidv4 } from "uuid";

import { convertTimestampToMilliseconds } from "common";

import { ProximityData, ProximityDataResponse } from "../../types";

export const mapProximityDataResponse = (response: ProximityDataResponse): ProximityData => ({
  ...response,
  id: uuidv4(),
  time: convertTimestampToMilliseconds(response.time),
  location: {
    lat: response.loc[1],
    lon: response.loc[0]
  },
  trackerName: response.name
});
