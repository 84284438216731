import CachedIcon from "@mui/icons-material/Cached";
import { AppBarProps, Layout as AdminLayout, LayoutProps, RefreshIconButton } from "react-admin";

import { AppMenu } from "./components/AppMenu";
import { AppTopBar } from "./components/AppTopBar";
type LunaLayoutProps = Omit<LayoutProps, "menu" | "appBar"> &
  Pick<AppBarProps, "userMenu"> & {
    appNavigation: React.ReactNode;
  };

export const Layout = ({ appNavigation, userMenu, ...props }: LunaLayoutProps) => (
  <AdminLayout
    {...props}
    // SX is missing in type declarations
    // https://marmelab.com/react-admin/Layout.html#sx-css-api
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    appBar={(props: AppBarProps) => (
      <AppTopBar
        {...props}
        userMenu={userMenu}
        toolbar={<RefreshIconButton icon={<CachedIcon color="action" />} />}
      >
        {appNavigation}
      </AppTopBar>
    )}
    menu={AppMenu}
    sx={{
      "& .RaSidebar-paper": {
        display: "none"
      },
      "& .RaLayout-content": {
        display: "flex",
        alignItems: "center",
        marginTop: 1
      }
    }}
  />
);
