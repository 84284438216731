import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateResult
} from "react-admin";

// For now, the provider is empty
// When the endpoints are available, we should use it to prefetch the proximity data
export const proximityFeedDataProvider: DataProvider = {
  // TODO not implemented yet
  getList: async (_, _params: GetListParams): Promise<GetListResult> => {
    return {
      data: [],
      total: 0
    };
  },
  getMany: async (_, _params: GetManyParams): Promise<GetManyResult> => ({ data: [] }),
  getOne: async (_, _params: GetOneParams): Promise<GetOneResult> => Promise.resolve({} as any),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  create: (_, _params) => Promise.resolve({ data: {} as any }),
  update: (_, _params): Promise<UpdateResult> => Promise.resolve({ data: {} as any }),
  updateMany: (_, _params: UpdateManyParams<any[]>) => Promise.resolve({}),
  delete: (_, _params) => Promise.resolve({ data: {} as any }),
  deleteMany: async (_, _params) => Promise.resolve({})
};
