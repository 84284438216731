import TextField from "@mui/material/TextField";
import { GridToolbarQuickFilter } from "@mui/x-data-grid-pro";

import { ChangeEventHandler, useState } from "react";
import { useListFilterContext } from "react-admin";

import { useListDataGridContext } from "../../context";

export const ListDataGridSearch = () => {
  const { filtersState } = useListDataGridContext();
  const listFilterContext = useListFilterContext();

  const [search, setSearch] = useState(listFilterContext.filterValues.query ?? "");

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;

    setSearch(value);
    listFilterContext.setFilters({ query: event.target.value }, "query");
  };

  if (filtersState === "client-side") {
    return <GridToolbarQuickFilter variant="outlined" />;
  }

  return (
    <TextField
      value={search}
      onChange={onChange}
      variant="outlined"
      label={search.length > 0 ? "Search" : "Search..."}
      type="search"
      sx={{ maxWidth: "250px", flex: 1, mt: 0, mb: 0 }}
      data-testid="listDataGridSearch"
    />
  );
};
